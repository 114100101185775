import React, { Component } from "react"
import Utils from "../../../utils/node.utils"
import axios from "axios"
import { store as tStore, setT, setMM, setMC, setTB } from "../../Store/Store"
import Loading from "../../Loading/Loading"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker"
import nl from "date-fns/locale/nl"
import Form from "../../ProductDetail/Form/Form"

registerLocale("nl", nl)

export default class Contact extends Component {
  state = {
    t: "",
    submitted: false,
    formValues: {
      question_email: "",
      question_first_name: "",
      question_comment_required: "",
      answer_email: "",
      answer_first_name: "",
      answer_comment_required: "",
      validation: "",
    },
    errors: [],
  }

  componentDidMount() {
    const { formValues } = this.state
    const { contentMap } = this.props

    formValues.question_email = contentMap.question_email.value
    formValues.question_first_name = contentMap.question_first_name.value
    formValues.question_comment_required =
      contentMap.question_comment_required.value

    this.setState({
      formValues: formValues,
    })

    this.unsubscribe = tStore.subscribe(this.storeUpdate)
    this.storeUpdate()
  }

  componentWillUnmount() {
    Utils.CancelToken(this.cancelToken)
    Utils.Unsubscribe(this.unsubscribe)
  }

  storeUpdate = () => {
    const storeState = tStore.getState()
    this.setState({
      t: storeState.t,
    })
  }

  storeUpdate = () => {
    const { t } = this.state
    const storeState = tStore.getState()

    if (storeState.t && storeState.t !== t) {
      this.setState({
        t: storeState.t,
      })
    }
  }

  handleChange = event => {
    const { formValues, errors } = this.state

    formValues[event.target.name] = event.target.value

    this.setState({
      formValues: formValues,
      errors: errors.filter(item => {
        return item !== event.target.name
      }),
    })
  }

  validateForm = () => {
    const { formValues, errors } = this.state

    if (formValues.validation !== "") errors.push("bulk_validation")

    if (!Utils.ValidateEmail(formValues.answer_email))
      errors.push("answer_email")
    if (formValues.answer_first_name === "") errors.push("answer_first_name")
    if (formValues.answer_comment_required === "")
      errors.push("answer_comment_required")
  }

  createFormBody = () => {
    const { language } = this.props
    const { formValues } = this.state

    const locale = Utils.LanguageToLocale(language)

    return {
      key: "contact",
      content: [
        {
          key: "question_email",
          value: formValues.question_email,
          locale: locale,
        },
        {
          key: "answer_email",
          value: formValues.answer_email,
          locale: locale,
        },
        {
          key: "question_first_name",
          value: formValues.question_first_name,
          locale: locale,
        },
        {
          key: "answer_first_name",
          value: formValues.answer_first_name,
          locale: locale,
        },
        {
          key: "question_comment_required",
          value: formValues.question_comment_required,
          locale: locale,
        },
        {
          key: "answer_comment_required",
          value: formValues.answer_comment_required,
          locale: locale,
        },
      ].map((item, index) => {
        item.index = index + 1
        return item
      }),
    }
  }

  onSubmit = event => {
    const { errors, t, formValues } = this.state
    const { contentMap, removeNotification, showTitle } = this.props

    event.preventDefault()

    this.validateForm()

    if (errors.length !== 0) {
      this.setState({
        errors: errors,
      })
      return
    }

    this.setState({
      loadingForm: true,
    })

    const body = this.createFormBody()
    // TODO: there was a reset here in newsletter
    this.setState({
      formValues: formValues,
    })

    this.cancelToken = axios.CancelToken.source()

    axios
      .post(
        `/enjocommerce/v1/forms`,
        body,
        Utils.RequestHeaders(t, this.cancelToken)
      )
      .then(() => {
        Utils.DisplayNotification(
          "fa fa-truck",
          contentMap.notification_submitted.value
        )
        tStore.dispatch(setT(""))
        this.setState(
          {
            submitted: true,
            loadingForm: showTitle,
          },
          removeNotification
        )
      })
      .catch(err => {
        this.setState({
          loadingForm: false,
        })

        if (Utils.LogAxiosError(err)) {
          return
        }
        if (err.response) {
          if (err.response.status == 401) {
            tStore.dispatch(setT(""))
          }
        }
      })
  }

  removeNotification = () => {
    if (removeNotification) {
      removeNotification()
    }
  }

  onClick = event => {
    const { removeNotification } = this.props

    event.preventDefault()
    if (removeNotification) {
      removeNotification()
    }
  }

  errorCss = (id, css) => {
    const { errors } = this.state
    if (errors && errors.includes(id)) {
      css += " forminput-invalid"
    }
    return css
  }

  onMouseMove = () => {
    const { mm, submitted } = this.state
    if (mm || submitted) {
      return
    }
    tStore.dispatch(setMM(true))
    this.setState({
      mm: true,
    })
  }

  onMouseClick = () => {
    const { mc, submitted } = this.state
    if (mc || submitted) {
      return
    }
    tStore.dispatch(setMC(true))
    this.setState({
      mc: true,
    })
  }

  onKeyDown = event => {
    const { tb, submitted } = this.state

    if (tb || submitted) {
      return
    }

    if (event) {
      if (event.key === "Tab") {
        tStore.dispatch(setTB(true))
        this.setState({
          tb: true,
        })
      }
    }
  }

  prefixID = id => {
    const { prefix } = this.props
    if (prefix) {
      return prefix + id
    }
    return id
  }

  confirmDisabled = () => {
    const { formValues } = this.state

    return (
      formValues.answer_email === "" ||
      formValues.answer_first_name === "" ||
      formValues.answer_comment_required === ""
    )
  }

  render() {
    const { contentMap, commonContentMap, showTitle, mCss } = this.props
    const { formValues, loadingForm } = this.state

    const marginCss = mCss ? " " + mCss : ""
    const titleCss = showTitle ? "" : " d-none"

    return (
      <div
        onMouseMove={this.onMouseMove}
        onClick={this.onMouseClick}
        onKeyDown={this.onKeyDown}
      >
        <div className={"container" + marginCss}>
          <div className={"row" + titleCss}>
            <div className="col-10 font-weight-bold text-uppercase"></div>
            <div className="col-2 text-right">
              <a
                href=""
                className="exit-ns-toggler"
                title={commonContentMap.close.value}
                onClick={this.onClick}
              >
                <i className="fa fa-window-close" />
              </a>
            </div>
          </div>
        </div>
        {loadingForm ? (
          <div className={"container" + marginCss}>
            <Loading />
          </div>
        ) : (
          <div className={"container" + marginCss}>
            <form onSubmit={this.onSubmit}>
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_email.value}
                </h6>
                <input
                  className={this.errorCss(
                    "answer_email",
                    "col-6 form-control"
                  )}
                  type="text"
                  id={this.prefixID("answer_email")}
                  name="answer_email"
                  value={formValues.answer_email}
                  onChange={this.handleChange}
                  placeholder={contentMap.question_email.value}
                  aria-label={contentMap.question_email.value}
                />
              </div>
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_first_name.value}
                </h6>
                <input
                  className={this.errorCss(
                    "answer_first_name",
                    "col-6 form-control"
                  )}
                  type="text"
                  id={this.prefixID("answer_first_name")}
                  name="answer_first_name"
                  value={formValues.answer_first_name}
                  onChange={this.handleChange}
                  placeholder={contentMap.question_first_name.value}
                  aria-label={contentMap.question_first_name.value}
                />
              </div>
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_comment_required.value}
                </h6>
                <textarea
                  className="col-6 form-control"
                  id={this.prefixID("answer_comment_required")}
                  name="answer_comment_required"
                  value={formValues.answer_comment_required}
                  onChange={this.handleChange}
                  placeholder=""
                  aria-label={contentMap.question_comment_required.value}
                />
              </div>
              <div className="row">
                <input
                  className="d-none form-control"
                  type="text"
                  id={this.prefixID("bulk_validation")}
                  name="bulk_validation"
                  value={formValues.bulk_validation}
                  onChange={this.handleChange}
                  placeholder={commonContentMap.validation.value}
                  aria-label={commonContentMap.validation.value}
                />
                <button
                  className="col-12 btn btn-pink"
                  id={this.prefixID("submit_bulk_form")}
                  type="submit_bulk_form"
                  disabled={this.confirmDisabled()}
                >
                  {commonContentMap.notification_newsletter_submit.value}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    )
  }
}
