import React, { Component } from "react"
import Utils from "../../../utils/node.utils"
import axios from "axios"
import { store as tStore, setT, setMM, setMC, setTB } from "../../Store/Store"
import Loading from "../../Loading/Loading"

export default class Newsletter extends Component {
  state = {
    t: "",
    submitted: false,
    formValues: {
      newsletter_email: "",
      newsletter_first_name: "",
      newsletter_validation: "",
    },
    errors: [],
  }

  componentDidMount() {
    this.unsubscribe = tStore.subscribe(this.storeUpdate)
    this.storeUpdate()
  }

  componentWillUnmount() {
    Utils.CancelToken(this.cancelToken)
    Utils.Unsubscribe(this.unsubscribe)
  }

  storeUpdate = () => {
    const storeState = tStore.getState()
    this.setState({
      t: storeState.t,
    })
  }

  handleChange = event => {
    const { formValues, errors } = this.state
    formValues[event.target.name] = event.target.value
    this.setState({
      formValues: formValues,
      errors: errors.filter(item => {
        return item !== event.target.name
      }),
    })
  }

  validateForm = () => {
    const { formValues, errors } = this.state
    if (formValues.newsletter_email === "") errors.push("newsletter_email")
    if (formValues.newsletter_first_name === "")
      errors.push("newsletter_first_name")
    if (formValues.newsletter_validation !== "")
      errors.push("newsletter_validation")
    if (!Utils.ValidateEmail(formValues.newsletter_email))
      errors.push("newsletter_email")
  }

  createUserBody = () => {
    const { language } = this.props
    const { formValues } = this.state
    return {
      email: formValues.newsletter_email,
      newsletter_user: true,
      locale: Utils.LanguageToLocale(language),
      content: [
        {
          key: "first_name",
          value: formValues.newsletter_first_name,
        },
      ],
    }
  }

  onSubmit = event => {
    const { errors, t, formValues } = this.state
    const { commonContentMap, removeNotification, showTitle } = this.props

    event.preventDefault()

    this.validateForm()

    if (errors.length !== 0) {
      this.setState({
        errors: errors,
      })
      return
    }

    this.setState({
      loadingUser: true,
    })

    if (localStorage) {
      localStorage.setItem("newsletter-notification", "true")
    }
    const body = this.createUserBody()
    formValues.newsletter_email = ""
    formValues.newsletter_first_name = ""
    this.setState({
      formValues: formValues,
    })

    this.cancelToken = axios.CancelToken.source()
    axios
      .post(
        `/enjocommerce/v1/users`,
        body,
        Utils.RequestHeaders(t, this.cancelToken)
      )
      .then(() => {
        Utils.DisplayNotification(
          "fa fa-heart",
          commonContentMap.notification_newsletter_subscribed.value
        )
        tStore.dispatch(setT(""))
        this.setState(
          {
            submitted: true,
            loadingUser: showTitle,
          },
          removeNotification
        )
      })
      .catch(err => {
        this.setState({
          loadingUser: false,
        })

        if (Utils.LogAxiosError(err)) {
          return
        }
        if (err.response) {
          if (err.response.status == 401) {
            tStore.dispatch(setT(""))
          }
        }
      })
  }

  removeNotification = () => {
    if (removeNotification) {
      removeNotification()
    }
  }

  onClick = event => {
    const { removeNotification } = this.props

    event.preventDefault()
    if (removeNotification) {
      removeNotification()
    }
  }

  errorCss = (id, css) => {
    const { errors } = this.state
    if (errors && errors.includes(id)) {
      css += " forminput-invalid"
    }
    return css
  }

  onMouseMove = () => {
    const { mm, submitted } = this.state
    if (mm || submitted) {
      return
    }
    tStore.dispatch(setMM(true))
    this.setState({
      mm: true,
    })
  }

  onMouseClick = () => {
    const { mc, submitted } = this.state
    if (mc || submitted) {
      return
    }
    tStore.dispatch(setMC(true))
    this.setState({
      mc: true,
    })
  }

  onKeyDown = event => {
    const { tb, submitted } = this.state

    if (tb || submitted) {
      return
    }

    if (event) {
      if (event.key === "Tab") {
        tStore.dispatch(setTB(true))
        this.setState({
          tb: true,
        })
      }
    }
  }

  prefixID = id => {
    const { prefix } = this.props
    if (prefix) {
      return prefix + id
    }
    return id
  }

  confirmDisabled = () => {
    const { formValues } = this.state
    return (
      formValues.newsletter_email === "" ||
      formValues.newsletter_first_name === ""
    )
  }

  render() {
    const { commonContentMap, showTitle, mCss } = this.props
    const { formValues, loadingUser } = this.state

    const marginCss = mCss ? " " + mCss : ""
    const titleCss = showTitle ? "" : " d-none"

    return (
      <div
        onMouseMove={this.onMouseMove}
        onClick={this.onMouseClick}
        onKeyDown={this.onKeyDown}
      >
        <div className={"container" + marginCss}>
          <div className={"row" + titleCss}>
            <div className="col-10 font-weight-bold text-uppercase">
              {commonContentMap.notification_newsletter_subscribe.value}
            </div>
            <div className="col-2 text-right">
              <a
                href=""
                title={commonContentMap.close.value}
                className="exit-ns-toggler"
                onClick={this.onClick}
              >
                <i className="fa fa-window-close" />
              </a>
            </div>
          </div>
        </div>
        {loadingUser ? (
          <div className={"container" + marginCss}>
            <Loading />
          </div>
        ) : (
          <div className={"container" + marginCss}>
            <form onSubmit={this.onSubmit}>
              <div className="row mb-2">
                <input
                  className={this.errorCss(
                    "newsletter_email",
                    "col-12 form-control"
                  )}
                  type="text"
                  id={this.prefixID("newsletter_email")}
                  name="newsletter_email"
                  value={formValues.newsletter_email}
                  onChange={this.handleChange}
                  placeholder={
                    commonContentMap.notification_newsletter_email.value
                  }
                  aria-label={
                    commonContentMap.notification_newsletter_email.value
                  }
                />
              </div>
              <div className="row">
                <input
                  className={this.errorCss(
                    "newsletter_first_name",
                    "col-6 form-control"
                  )}
                  type="text"
                  id={this.prefixID("newsletter_first_name")}
                  name="newsletter_first_name"
                  value={formValues.newsletter_first_name}
                  onChange={this.handleChange}
                  placeholder={
                    commonContentMap.notification_newsletter_first_name.value
                  }
                  aria-label={
                    commonContentMap.notification_newsletter_first_name.value
                  }
                />
                <input
                  className="d-none form-control"
                  type="text"
                  id={this.prefixID("newsletter_validation")}
                  name="newsletter_validation"
                  value={formValues.newsletter_validation}
                  onChange={this.handleChange}
                  placeholder={commonContentMap.validation.value}
                  aria-label={commonContentMap.validation.value}
                />
                <button
                  className="col-6 btn btn-pink"
                  id={this.prefixID("submit_newsletter_subscribe")}
                  type="submit_newsletter_subscribe"
                  disabled={this.confirmDisabled()}
                >
                  {commonContentMap.notification_newsletter_submit.value}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    )
  }
}
