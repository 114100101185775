import FingerprintJS from "@fingerprintjs/fingerprintjs"
import axios from "axios"
import Utils from "../utils/node.utils"

const p = "2F4EB273A64347348BCC33E320EBB1EE"

function gfp(cb, cb2) {
  const cancelToken = axios.CancelToken.source()

  const fpPromise = FingerprintJS.load()
  fpPromise
    .then(fp => fp.get())
    .then(function(fp) {
      cb(fp.components, cb2, cancelToken)
    })
  return cancelToken
}

function ck() {
  return p + Date.now()
}

function checkVal(value, typ, defaultVal) {
  if (typeof value.value === typ) {
    return value.value
  }
  return defaultVal
}

function creq(fp) {
  if (!fp) {
    return
  }

  let req = {}
  for (let key in fp) {
    const value = fp[key]
    switch (key) {
      // Gone
      // case "userAgent":
      case "vendor":
        req["user_agent"] = checkVal(value, "string", "")
        break
      case "languages":
        const languages = checkVal(value, "string", "")
        if (languages.length && languages.length > 0) {
          req["language"] = languages[0]
        }
        break
      case "colorDepth":
        req["color_depth"] = checkVal(value, "number", 0)
        break
      case "deviceMemory":
        req["device_memory"] = checkVal(value, "number", 0)
        break
      case "hardwareConcurrency":
        req["hardware_concurrency"] = checkVal(value, "number", 0)
        break
      case "screenResolution":
        req["screen_resolution"] = checkVal(value, "object", [])
        break
      case "plugins":
        req["plugins"] = value.length ? value.length : 0
        break
      // case "timezoneOffset":
      //   req["timezone_offset"] = checkVal(value, "number", 0)
      //   break
      case "timezone":
        req["timezone"] = checkVal(value, "string", "")
        break
      case "sessionStorage":
        req["session_storage"] = checkVal(value, "boolean", false)
        break
      case "localStorage":
        req["local_storage"] = checkVal(value, "boolean", false)
        break
      case "indexedDB":
        req["indexed_db"] = checkVal(value, "boolean", false)
        break
      case "platform":
        req["platform"] = checkVal(value, "string", "")
        break
      // case "adBlock":
      //   req["adblock"] = value
      //   break
      // case "hasLiedLanguages":
      //   if (item.value) req["has_lied_languages"] = value
      //   break
      // case "hasLiedResolution":
      //   if (item.value) req["has_lied_resolution"] = value
      //   break
      // case "hasLiedOs":
      //   if (item.value) req["has_lied_os"] = value
      //   break
      // case "hasLiedBrowser":
      //   if (item.value) req["has_lied_browser"] = value
      //   break
    }
  }

  req["public"] = ck()
  return req
}

function cfp(fp, cb, cancelToken) {
  const req = creq(fp)

  axios
    .post(
      `/enjocommerce/v1/login`,
      { guest: req },
      Utils.RequestHeaders(undefined, cancelToken)
    )
    .then(data => {
      const parts = data.data.jwt.split(".")
      if (parts.length !== 3) {
        return
      }
      const payload = JSON.parse(atob(parts[1]))
      cb(data.data.jwt, payload["keys"].split("."))
    })
    .catch(err => {
      if (Utils.LogAxiosError(err, true)) {
        return
      }
      cb()
    })

  return cancelToken
}

function afp(t, index, cb) {
  const req = {
    token_pair: {
      jwt: t,
      key: index,
      value: "t",
    },
  }
  const cancelToken = axios.CancelToken.source()
  axios
    .post(`/enjocommerce/v1/login`, { guest: req }, Utils.RequestHeaders(t))
    .then(data => {
      cb(data.data.jwt)
    })
    .catch(err => {
      if (Utils.LogAxiosError(err)) {
        return
      }
      cb()
    })
  return cancelToken
}

export default {
  Gat(cb) {
    return gfp(cfp, cb)
  },
  Mat(t, index, cb) {
    return afp(t, index, cb)
  },
  Eat(t) {
    if (!t) {
      return Date.now()
    }
    const parts = t.split(".")
    if (parts.length !== 3) {
      return 0
    }
    const payload = JSON.parse(atob(parts[1]))
    return payload.exp * 1000
  },
  Ck() {
    return ck()
  },
}
