import { Component } from "react"
import Acrud from "../../acrud/node.acrud"
import Utils from "../../utils/node.utils"
import { store, setT } from "../Store/Store"

export default class Guest extends Component {
  rt = {
    expires: Date.now(),
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.storeUpdate)
    this.gat()
    this.interval = setInterval(this.refreshT, 30 * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    Utils.CancelToken(this.gatCancelToken)
    Utils.CancelToken(this.matCancelToken)
    Utils.Unsubscribe(this.unsubscribe)
  }

  gat = () => {
    this.loading = true
    this.gatCancelToken = Acrud.Gat(this.setT)
  }

  mat = id => {
    const { t } = this.rt
    this.loading = true
    this.matCancelToken = Acrud.Mat(t, id, this.modT)
  }

  setT = (t, keys) => {
    if (!t) {
      console.warn("Failed to perform guest login")
      this.loading = false
      return
    }
    this.rt.t = t
    this.rt.mm = false
    this.rt.mc = false
    this.rt.tb = false
    this.rt.keys = keys
    this.rt.expires = Acrud.Eat(t)
    store.dispatch(setT(t))
    this.loading = false
  }

  refreshT = () => {
    const { expires, mm, mc, tb } = this.rt

    if (this.loading) {
      return
    }

    if (expires && expires < Date.now() + 60000) {
      if ((!mm && !mc && !tb) || expires <= Date.now()) {
        this.gat()
      }
    }
  }

  modT = t => {
    this.rt.t = t
    this.rt.expires = Acrud.Eat(t)
    store.dispatch(setT(t))
    this.loading = false
  }

  storeUpdate = () => {
    const { keys } = this.rt
    const storeState = store.getState()

    if (!storeState.t || storeState.t === "") {
      this.gat()
      return
    }

    this.rt.t = storeState.t

    if (!keys) {
      return
    }

    if (storeState.mm && storeState.mm !== this.rt.mm) {
      this.rt.mm = storeState.mm
      this.mat(keys[0])
    }
    if (storeState.mc && storeState.mc !== this.rt.mc) {
      this.rt.mc = storeState.mc
      this.mat(keys[1])
    }
    if (storeState.tb && storeState.tb !== this.rt.tb) {
      this.rt.tb = storeState.tb
      this.mat(keys[0])
    }
  }

  render() {
    return null
  }
}
