import { ReactNotifications } from "react-notifications-component"
import "react-notifications-component/dist/theme.css"
import React from "react"
import Guest from "../components/Guest/Guest"

export default ({ element }) => (
  <div>
    <ReactNotifications isMobile={true} />
    <Guest />
    {element}
  </div>
)
