import React from "react"
import Notification from "../components/Shared/Notification/Notification"
import NewsletterNotification from "../components/Shared/Notification/NewsletterNotification"
import ShippingNotification from "../components/Shared/Notification/ShippingNotification"
import BulkNotification from "../components/Shared/Notification/BulkNotification"
import ContactNotification from "../components/Shared/Notification/ContactNotification"
import { Store } from "react-notifications-component"
import axios from "axios"
import { Link, navigate } from "gatsby"
import ReactMarkdown from "react-markdown"
import TagManager from "react-gtm-module"
import Cookies from "universal-cookie"
import {
  store as tStore,
  setT,
  setCart,
  setWishlist,
} from "../components/Store/Store"

const requestTimeout = 30000
const orderCheckTimeout = 3600000
const currencies = { EUR: "€", USD: "$" }
const notificationDuration = 2000
const cancelMsg = "x_sbf_axios_request_cancel"
const gtmID = "GTM-P3NF865"

if (!Date.now) {
  Date.now = function() {
    return new Date().getTime()
  }
}

export default {
  GetCurrencySymbol(currency) {
    if (currency in currencies) {
      return currencies[currency]
    }
    return currency
  },
  IsInputVariation(variationObject) {
    return (
      variationObject.input &&
      (variationObject.input.charset != "" ||
        variationObject.input.minimum ||
        variationObject.input.maximum)
    )
  },
  GetVariationVars(variationSequences, fullVariations, language) {
    let variations = {}
    let variationMap = {}
    let optionMap = {}

    for (let i in fullVariations) {
      const fullVariation = fullVariations[i].variation

      variations[fullVariation.id] = fullVariations[i]

      if (this.IsInputVariation(fullVariation)) {
        variationMap[fullVariation.key] = {
          id: fullVariation.id,
          content: fullVariation.content,
          input: fullVariation.input,
        }
      } else {
        // One level up!
        if (!this.IsSequenceVariation(fullVariations[i])) {
          let variationOptions = {}

          for (let i in fullVariation.options) {
            const option = fullVariation.options[i]
            const optionName = this.GetContentKeyValueWithDefault(
              option.content,
              "name",
              this.LanguageToLocale(language)
            )
            variationOptions[optionName] = option.id
          }

          variationMap[fullVariation.key] = {
            id: fullVariation.id,
            content: fullVariation.content,
            options: variationOptions,
          }
        }
      }

      for (let j in fullVariation.options) {
        const option = fullVariation.options[j]
        optionMap[option.id] = option
      }
    }

    if (variationSequences) {
      for (let i in variationSequences) {
        const variationSequence = variationSequences[i]

        for (let j in variationSequence.sequence) {
          let variationOptions = {}
          let variation = variationSequence.sequence[j]
          let variationContent = variations[variation.variation.id]

          if (variationContent.has_quantity) {
            if (
              !variationSequence.data.quantity ||
              variationSequence.data.quantity === 0
            ) {
              // TODO: we could just mark it disabled and disable the button
              break
            }
          }

          if (variationContent.variation.key in variationMap) {
            variationOptions =
              variationMap[variationContent.variation.key]["options"]
          }

          if (!variation.option || !variation.option.id) {
            // Custom input
            for (let i = 0; i < fullVariations.length; i++) {
              const fv = fullVariations[i]
              if (fv.id === variation.id) {
                variationOptions = fv.options
              }
            }
          } else {
            const option = optionMap[variation.option.id]
            const optionName = this.GetContentKeyValueWithDefault(
              option.content,
              "name",
              this.LanguageToLocale(language)
            )
            variationOptions[optionName] = variation.option.id
          }

          variationMap[variationContent.variation.key] = {
            id: variation.variation.id,
            content: variationContent.variation.content,
            options: variationOptions,
          }
        }
      }
    }
    return variationMap
  },
  ParseCategoryFilters(search, defaultParams) {
    let out = defaultParams

    for (let k in out) {
      out[k] = false
    }

    if (!search) {
      return out
    }

    const params = this.ParseURLQuery(search)

    if (!params.categories) {
      return out
    }

    let paramsStrings = params.categories.split(",")

    for (let i = 0; i < paramsStrings.length; i++) {
      const param = paramsStrings[i]
      if (param in out) {
        out[param] = true
      }
    }

    return out
  },
  reduceZeroQuantities(cart, item) {
    if (item.quantity !== 0) {
      cart.push(item)
    }
    return cart
  },
  SetCart(storageKey, cart) {
    localStorage.setItem(storageKey, JSON.stringify(cart))
  },
  GetCart(storageKey) {
    // Get existing cart from localstorage if present.
    if (typeof window !== "undefined") {
      try {
        const existingCart = JSON.parse(localStorage.getItem(storageKey))
        if (existingCart && existingCart.length) {
          return existingCart
        }
      } catch {
        localStorage.removeItem(storageKey)
        return []
      }
    }
    return []
  },
  SetCartProduct(id, pVariations, storageKey, quantity, cb) {
    const checkoutLink = this.OrderInProgress()
    if (checkoutLink) {
      //this.DisplayOrderInProgressNotification(commonContentMap)
      return
    }
    this.loadingCart = true
    let updatedCart = this.GetCart(storageKey)
      .map(item => {
        if (
          id === item.id &&
          this.EqualVariations(pVariations, item.variations)
        ) {
          return { id: item.id, variations: pVariations, quantity: quantity }
        } else {
          return item
        }
      })
      .reduce(this.reduceZeroQuantities, [])
    localStorage.setItem(storageKey, JSON.stringify(updatedCart))
    this.doneLoadingCart(cb, false, updatedCart)
  },
  getCartName(isWishlist) {
    if (isWishlist) {
      return " wishlist"
    }
    return "cart"
  },
  EqualVariations(x, y) {
    if (x === y) {
      return true
    }

    if (!x) {
      x = {}
    }
    if (!y) {
      y = {}
    }

    let keysx = Object.keys(x)
    let keysy = Object.keys(y)
    let key

    if (keysx.length !== keysy.length) {
      return false
    }

    if (keysx.length === 0 && keysy.length === 0) {
      return true
    }

    for (key in x) {
      if (!(key in y)) {
        return false
      }
      if (x[key] !== y[key]) {
        return false
      }
    }

    return true
  },
  GetOrder(id, t, cb, clientConfirmation) {
    if (!t) {
      console.warn("Cannot read order, not authenticated")
      return
    }

    const cancelToken = axios.CancelToken.source()

    axios
      .get(`/enjocommerce/v1/orders/${id}`, this.RequestHeaders(t, cancelToken))
      .then(data => {
        const order = data.data

        if (order.status !== "open") {
          localStorage.removeItem("ss_o")
        }

        if (order.status !== "paid" && order.status !== "open") {
          const existingCart = this.GetCart("cart-order")
          if (existingCart) {
            localStorage.setItem("cart-items", JSON.stringify(existingCart))
            localStorage.removeItem("cart-order")
          }

          if (clientConfirmation) {
            for (let i in order.products) {
              const product = order.products[i]

              this.DoGoogleTagEvent({
                dataLayer: {
                  event: "checkoutFailed",
                  order: {
                    id: order.id,
                    price: product.data.price,
                    quantity: product.data.quantity,
                    product_id: product.id,
                  },
                },
              })
            }
          }
        }

        if (order.status === "paid") {
          let formValues = JSON.parse(localStorage.getItem("form-values"))
          if (formValues) {
            formValues.discount_code = ""
            localStorage.setItem("form-values", JSON.stringify(formValues))
          }

          if (clientConfirmation) {
            for (let i in order.products) {
              const product = order.products[i]

              this.DoGoogleTagEvent({
                dataLayer: {
                  event: "checkoutCompleted",
                  order: {
                    id: order.id,
                    price: product.data.price,
                    quantity: product.data.quantity,
                    product_id: product.id,
                  },
                },
              })
            }
          }
        }
        if (cb) {
          cb(order)
        }
      })
      .catch(err => {
        if (this.LogAxiosError(err)) {
          return
        }
        this.loaded = false
        if (err.response) {
          if (err.response.status == 404) {
            localStorage.removeItem("ss_o")
            localStorage.removeItem("cart-order")
          }
        }
        const existingCart = this.GetCart("cart-order")
        if (existingCart) {
          localStorage.setItem("cart-items", JSON.stringify(existingCart))
          localStorage.removeItem("cart-order")
        }
      })
    return cancelToken
  },
  OrderInProgress(t, cb) {
    if (localStorage) {
      try {
        const existingOrder = JSON.parse(localStorage.getItem("ss_o"))

        if (existingOrder) {
          if (
            existingOrder.time &&
            existingOrder.time + orderCheckTimeout > Date.now()
          ) {
            if (t) {
              this.GetOrder(existingOrder.id, t, cb)
            }
          } else {
            localStorage.removeItem("ss_o")
          }
          return existingOrder.checkout_link
        }
      } catch (err) {
        console.warn("error reading existing order: ", err)
        localStorage.removeItem("ss_o")
      }
    }
  },
  GetVariationReductionAmount(countRes, quantity, newQuantity) {
    if (countRes.count === 0) {
      return newQuantity
    }
    return (
      quantity -
      Math.ceil(
        (countRes.quantity + quantity - newQuantity) / (countRes.count + 1)
      )
    )
  },
  IsSequenceVariation(variation) {
    if (variation.has_quantity === true) {
      return true
    }
    if (variation.has_price === true) {
      return true
    }
    if (variation.has_sku === true) {
      return true
    }
    return false
  },
  // GetSameVariationCounts(id, pVariations, cart, fullVariations) {
  //   let res = {
  //     quantity: 0,
  //     count: 0,
  //   }

  //   if (!fullVariations) {
  //     return res
  //   }

  //   if (!cart) {
  //     cart = this.GetCart("cart-items")
  //   }

  //   let variationMap = {}

  //   for (let i in fullVariations) {
  //     const variation = fullVariations[i]
  //     variationMap[variation.variation.id] = variation
  //   }

  //   if (pVariations && Object.keys(pVariations).length !== 0) {
  //     for (let key in pVariations) {
  //       const variation = variationMap[key]

  //       if (!this.IsSequenceVariation(variation)) {
  //         console.log("cart check: ", cart)
  //         for (let i in cart) {
  //           console.log("cart pVariations: ", pVariations)
  //           console.log("cart cart[i].variations: ", cart[i].variations)
  //           if (
  //             cart[i].id === id &&
  //             !this.EqualVariations(pVariations, cart[i].variations)
  //           ) {
  //             res.count++
  //             res.quantity += cart[i].quantity
  //             console.log("found")
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return res
  // },
  // AddVariationsIfSame(id, pVariations, cart, fullVariations) {
  //   const res = this.GetSameVariationCounts(
  //     id,
  //     pVariations,
  //     cart,
  //     fullVariations
  //   )
  //   return res.quantity
  // },
  doneLoadingCart(cb, isWishlist, cart) {
    this.loadingCart = false
    if (isWishlist) {
      tStore.dispatch(setWishlist(true))
    } else {
      tStore.dispatch(setCart(true))
    }
    if (cb) {
      cb(cart)
    }
  },
  AddToCart(
    product,
    pVariations,
    storageKey,
    isWishlist,
    commonContentMap,
    cb,
    t
  ) {
    if (!t && !isWishlist) {
      console.warn("Cannot add to cart, not authenticated")
      return
    }

    const id = product.id
    const fullVariations = product.variations

    if (this.loadingCart) {
      return
    }
    this.loadingCart = true

    // Convert variations if they are an array
    if (pVariations && pVariations.length) {
      let cVariations = {}
      for (let i in pVariations) {
        const variation = pVariations[i]
        if (variation.option) {
          cVariations[variation.variation.id] = variation.option.id
        }
      }
      pVariations = cVariations
    }

    let quantity = 0
    let updatedCart = this.GetCart(storageKey).map(item => {
      if (id === item.id) {
        if (isWishlist) {
          quantity = 1
          return item
        }

        if (this.EqualVariations(pVariations, item.variations)) {
          quantity = ++item.quantity
          return {
            id: item.id,
            variations: pVariations,
            quantity: item.quantity,
          }
        }
      }
      return item
    })

    // If the product was not found, add it
    if (quantity === 0) {
      updatedCart = [
        ...updatedCart,
        { id: id, variations: pVariations, quantity: 1 },
      ]
      quantity = 1
    }

    if (isWishlist) {
      localStorage.setItem(storageKey, JSON.stringify(updatedCart))
      this.DisplayAddToWishlistNotification(commonContentMap)
      this.doneLoadingCart(cb, isWishlist, updatedCart)
      this.DoGoogleTagEvent({
        dataLayer: {
          event: "addToWishlist",
          wishlist: {
            product_id: id,
          },
        },
      })
      return
    }
    const checkoutLink = this.OrderInProgress()
    if (checkoutLink) {
      this.DisplayOrderInProgressNotification(commonContentMap)
      this.doneLoadingCart(cb, isWishlist, updatedCart)
      return
    }
    // quantity += this.AddVariationsIfSame(
    //   id,
    //   pVariations,
    //   updatedCart,
    //   fullVariations
    // )

    const cancelToken = axios.CancelToken.source()
    axios
      .post(
        `/enjocommerce/v1/check/products`,
        this.CreateQuantityCheckBody(id, pVariations, quantity),
        this.RequestHeaders(t, cancelToken)
      )
      .then(data => {
        if (data.data.products[0].data.quantity < quantity) {
          updatedCart = this.GetCart(storageKey)
          this.DisplayNoMoreAvailableNotification(commonContentMap)
        } else {
          localStorage.setItem(storageKey, JSON.stringify(updatedCart))
          this.DisplayAddToCartNotification(commonContentMap)
          this.DoGoogleTagEvent({
            dataLayer: {
              event: "addToCart",
              cart: {
                product_id: id,
                price: this.GetProductPrice(product),
              },
            },
          })
        }
        this.doneLoadingCart(cb, isWishlist, updatedCart)
      })
      .catch(err => {
        this.LogAxiosError(err)
        this.doneLoadingCart(cb, isWishlist, updatedCart)
      })
    return cancelToken
  },
  RemoveFromCart(
    product,
    pVariations,
    storageKey,
    isWishlist,
    commonContentMap,
    cb
  ) {
    if (this.loadingCart) {
      return
    }
    this.loadingCart = true

    const id = product.id

    if (!isWishlist) {
      const checkoutLink = this.OrderInProgress()
      if (checkoutLink) {
        this.DisplayOrderInProgressNotification(commonContentMap)
        this.doneLoadingCart(cb, isWishlist)
        return
      }
    }

    let newCart = this.GetCart(storageKey)
      .map(item => {
        if (
          item.id === id &&
          this.EqualVariations(pVariations, item.variations)
        ) {
          item.quantity--
        }
        return item
      })
      .reduce(this.reduceZeroQuantities, [])
    localStorage.setItem(storageKey, JSON.stringify(newCart))
    if (isWishlist) {
      this.DisplayRemoveFromWishlistNotification(commonContentMap)
      this.DoGoogleTagEvent({
        dataLayer: {
          event: "removeFromWishlist",
          wishlist: {
            product_id: id,
          },
        },
      })
    } else {
      this.DisplayRemoveFromCartNotification(commonContentMap)
      // Price is already the correct one here (not in key 'data')
      this.DoGoogleTagEvent({
        dataLayer: {
          event: "removeFromCart",
          cart: {
            product_id: id,
            price: product.price,
          },
        },
      })
    }
    this.doneLoadingCart(cb, isWishlist, newCart)
  },
  DisplayNotification(iconClass, text) {
    Store.addNotification({
      content: <Notification iconClass={iconClass} text={text} />,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "slideInDown"],
      animationOut: ["animated", "slideOutUp"],
      dismiss: {
        duration: notificationDuration,
      },
    })
  },
  DisplayNewsletterNotification(commonContentMap) {
    if (!window) {
      return
    }

    const language = this.GetLanguage()
    if (localStorage.getItem("newsletter-notification")) {
      return
    }

    if (sessionStorage) {
      if (sessionStorage.getItem("newsletter-notification")) {
        return
      }
    }

    Store.removeNotification("newsletter-notification")
    const timeoutID = setTimeout(() => {
      Store.removeNotification("newsletter-notification")
      Store.addNotification({
        id: "newsletter-notification",
        content: (
          <NewsletterNotification
            id="newsletter-notification"
            language={language}
            commonContentMap={commonContentMap}
          />
        ),
        insert: "top",
        container: "bottom-left",
        width: 400,
        dismiss: {
          duration: 0,
          click: false,
          touch: false,
        },
        animationIn: ["animated", "slideInUp"],
        animationOut: ["animated", "slideOutDown"],
      })
      if (sessionStorage) {
        sessionStorage.setItem("newsletter-notification", "true")
      }
    }, 3000)
    return timeoutID
  },
  DisplayContactNotification(contentMap, commonContentMap) {
    if (!window) {
      return
    }

    const language = this.GetLanguage()

    Store.addNotification({
      id: "contact-notification",
      content: (
        <ContactNotification
          id="contact-notification"
          language={language}
          contentMap={contentMap}
          commonContentMap={commonContentMap}
        />
      ),
      insert: "top",
      container: "center",
      width: 1000,
      dismiss: {
        duration: 0,
        click: false,
        touch: false,
      },
      animationIn: ["animated", "slideInUp"],
      animationOut: ["animated", "slideOutDown"],
    })

    return
  },
  DisplayBulkNotification(contentMap, commonContentMap) {
    if (!window) {
      return
    }

    const language = this.GetLanguage()

    Store.addNotification({
      id: "bulk-notification",
      content: (
        <BulkNotification
          id="bulk-notification"
          language={language}
          contentMap={contentMap}
          commonContentMap={commonContentMap}
        />
      ),
      insert: "top",
      container: "center",
      width: 1000,
      dismiss: {
        duration: 0,
        click: false,
        touch: false,
      },
      animationIn: ["animated", "slideInUp"],
      animationOut: ["animated", "slideOutDown"],
    })

    return
  },
  DisplayShippingNotification(
    contentMap,
    commonContentMap,
    country,
    countries,
    shippingPrice
  ) {
    if (!window) {
      return
    }

    try {
      const language = this.GetLanguage()

      Store.removeNotification("shipping-notification")
      const timeoutID = setTimeout(() => {
        Store.removeNotification("shipping-notification")
        Store.addNotification({
          id: "shipping-notification",
          content: (
            <ShippingNotification
              id="shipping-notification"
              language={language}
              contentMap={contentMap}
              commonContentMap={commonContentMap}
              shippingPrice={
                shippingPrice.freeShippingFloat - shippingPrice.totalFloat
              }
              currency={shippingPrice.currency}
              country={country}
              countries={countries}
            />
          ),
          insert: "top",
          container: "bottom-left",
          width: 400,
          dismiss: {
            duration: 0,
            click: false,
            touch: false,
          },
          animationIn: ["animated", "slideInUp"],
          animationOut: ["animated", "slideOutDown"],
        })
      }, 1000)
      return timeoutID
    } catch (err) {
      console.error(err)
      return
    }
  },
  DisplayAddToCartNotification(commonContentMap) {
    this.DisplayNotification(
      "fa fa-cart-arrow-down",
      commonContentMap.notification_added_to_cart.value
    )
  },
  DisplayRemoveFromCartNotification(commonContentMap) {
    this.DisplayNotification(
      "fa fa-minus",
      commonContentMap.notification_removed_from_cart.value
    )
  },
  DisplayAddToWishlistNotification(commonContentMap) {
    this.DisplayNotification(
      "fa fa-heart",
      commonContentMap.notification_added_to_wishlist.value
    )
  },
  DisplayRemoveFromWishlistNotification(commonContentMap) {
    this.DisplayNotification(
      "fa fa-minus",
      commonContentMap.notification_removed_from_wishlist.value
    )
  },
  DisplayNoMoreAvailableNotification(commonContentMap) {
    this.DisplayNotification(
      "fa fa-exclamation-triangle",
      commonContentMap.notification_no_more_available.value
    )
  },
  DisplayOrderInProgressNotification(commonContentMap) {
    this.DisplayNotification(
      "fa fa-exclamation-triangle",
      commonContentMap.notification_payment_in_progress.value
    )
  },
  RemoveNotifications() {
    // if (Store && Store.removeNotification) {
    //   Store.removeNotification("newsletter-notification")
    //   Store.removeNotification("shipping-notification")
    // }
    if (sessionStorage) {
      sessionStorage.removeItem("newsletter-notification")
    }
  },
  RemoveShippingNotification() {
    if (Store && Store.removeNotification) {
      Store.removeNotification("shipping-notification")
    }
  },
  CreateURLQuery(params) {
    let query = ""
    let sep = "?"

    for (let key in params) {
      query += sep + key + "=" + params[key]
      sep = "&"
    }

    return query
  },
  ParseURLQuery(query) {
    let out = {}
    if (!query.startsWith("?")) {
      return out
    }
    query = query.substring(1)

    let vars = query.split("&")
    for (var i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=")

      if (pair.length !== 2) {
        continue
      }

      out[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }

    return out
  },
  NavigateToBrowserLanguage(currentLang, page, state, query) {
    if (typeof window === "undefined") {
      return
    }

    if (query) {
      const parsedQuery = this.ParseURLQuery(query)

      if (parsedQuery.noforward === "true") {
        return
      }

      page += query
    }

    if (state && state.lang) {
      localStorage.setItem("lang", JSON.stringify({ value: state.lang }))
      this.RemoveNotifications()
      return
    }
    let existingLanguage
    try {
      existingLanguage = JSON.parse(localStorage.getItem("lang"))

      switch (existingLanguage.value) {
        case "en":
        case "es":
        case "nl":
          break
        default:
          localStorage.removeItem("lang")
          existingLanguage = undefined
      }
    } catch {
      localStorage.removeItem("lang")
    }

    if (existingLanguage && Object.keys(existingLanguage).length) {
      if (!currentLang && existingLanguage.value === "en") {
        return
      }

      if (currentLang === existingLanguage.value) {
        return
      }
      if (existingLanguage.value === "en") {
        this.RemoveNotifications()
        navigate(page)
        return
      }

      this.RemoveNotifications()
      navigate("/" + existingLanguage.value + page)
      return
    }

    if (currentLang === "nl" || currentLang === "es") {
      return
    }

    let language = window.navigator.userLanguage || window.navigator.language
    if (language.includes("es")) {
      localStorage.setItem("lang", JSON.stringify({ value: "es" }))
      this.RemoveNotifications()
      navigate("/es" + page)
      return
    }

    if (language.includes("nl")) {
      localStorage.setItem("lang", JSON.stringify({ value: "nl" }))
      this.RemoveNotifications()
      navigate("/nl" + page)
      return
    }
    localStorage.setItem("lang", JSON.stringify({ value: "en" }))
    this.RemoveNotifications()
    navigate(page)
  },
  GetLanguage() {
    if (typeof window === "undefined") {
      return
    }
    let existingLanguage
    try {
      existingLanguage = JSON.parse(localStorage.getItem("lang"))
    } catch {
      localStorage.removeItem("lang")
    }
    if (existingLanguage && Object.keys(existingLanguage).length) {
      if (existingLanguage.value === "en") {
        return
      }
      return existingLanguage.value
    }
    return
  },
  GetContent(lang, content, index) {
    if (lang) {
      return content[index][lang + "text"]
    }
    return content[index].text
  },
  FullProductID(product) {
    let ids = [product.id]
    for (let k in product.variations) {
      ids.push(product.variations[k])
    }
    return ids.sort().join("")
  },
  ValidateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  },
  Sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },
  GetPriceString(price) {
    return this.GetCurrencySymbol(price.currency) + " " + price.value
  },
  GetConfirmPriceString(currency, price, discount) {
    let total = price

    if (discount) {
      total -= discount
    }
    return this.GetCurrencySymbol(currency) + "" + total
  },
  GetDisplayPrice(price, bold) {
    const currencySymbol = this.GetCurrencySymbol(price.currency)
    if (price.discount_value) {
      return (
        <div>
          <div className={bold ? "font-weight-bold" : ""}>
            {currencySymbol +
              " " +
              this.CalculateDiscountedPrice(price.value, price.discount_value)}
          </div>
          <div className="text-muted text-decoration-line-through small">
            {currencySymbol + " " + price.value}
          </div>
        </div>
      )
    }

    return (
      <div className={bold ? "font-weight-bold" : ""}>
        {currencySymbol + " " + price.value}
      </div>
    )
  },
  CalculateDiscountedPrice(value, discountValue) {
    const valueFlt = parseFloat(value)
    const discountValueFlt = parseFloat(discountValue)

    return (valueFlt - discountValueFlt).toFixed(2)
  },
  GetDisplayPriceHor(price) {
    if (!price || !price.value) {
      return <div></div>
    }

    const currencySymbol = this.GetCurrencySymbol(price.currency)
    if (price.discount_value) {
      return (
        <div>
          <span>
            {currencySymbol +
              " " +
              this.CalculateDiscountedPrice(price.value, price.discount_value)}
            &nbsp;
          </span>
          <span className="text-muted text-decoration-line-through small">
            {currencySymbol + " " + price.value}
          </span>
        </div>
      )
    }

    return <div>{currencySymbol + " " + price.value}</div>
  },
  GetCountryOptions(countries) {
    return countries.map(item => {
      return (
        <option value={item.code} key={item.code}>
          {item.info[0].name}
        </option>
      )
    })
  },
  LanguageToLocale(language) {
    switch (language) {
      case "en":
        return "en_GB"
      case "es":
        return "es_ES"
      case "nl":
        return "nl_NL"
      default:
        return "en_GB"
    }
  },
  FetchCountries(language, t, cb) {
    if (!t) {
      console.warn("Cannot fetch countries, not authenticated")
      return
    }

    let query = ""
    if (language) {
      query += `?locale=${this.LanguageToLocale(language)}`
    }

    const cancelToken = axios.CancelToken.source()
    axios
      .get(
        `/enjocommerce/v1/countries${query}`,
        this.RequestHeaders(t, cancelToken)
      )
      .then(data => {
        let countries = []
        for (let i in data.data.pages) {
          countries.push(data.data.pages[i])
        }
        cb(countries)
      })
      .catch(err => {
        if (this.LogAxiosError(err)) {
          return
        }
        cb([])
      })
    return cancelToken
  },
  FetchShippingPrice(country, t, cb) {
    if (!t) {
      console.warn("Cannot fetch shipping groups, not authenticated")
      return
    }

    let uri = `/enjocommerce/v1/shipping_groups?code=${country}`

    const cancelToken = axios.CancelToken.source()
    axios
      .get(uri, this.RequestHeaders(t, cancelToken))
      .then(data => {
        cb(data.data)
      })
      .catch(err => {
        if (this.LogAxiosError(err)) {
          return
        }
        cb({ total: 0 })
      })
    return cancelToken
  },
  ContentListToMap(content) {
    let contentMap = {}

    if (!content) {
      return contentMap
    }

    for (let i = 0; i < content.length; i++) {
      contentMap[content[i].key] = content[i]
    }

    return contentMap
  },
  GetSingleContentLink(content) {
    let retval = { value: "", link: "" }

    if (!content || !content.value) {
      return retval
    }

    retval.value = content.value.match(/\[([\S\s]*?)\]/)[1]
    retval.link = content.value.match(/\(([\S\s]*?)\)/)[1]

    return retval
  },
  GetLanguageLink(language, link) {
    if (language) {
      link = "/" + language + link
    }
    return link
  },
  RequestHeaders(t, cancelToken) {
    let config = {
      headers: {
        Accept: "application/json",
      },
      timeout: requestTimeout,
    }

    if (t) {
      config.headers["Authorization"] = t
    }

    if (cancelToken) {
      config["cancelToken"] = cancelToken.token
    }
    return config
  },
  CreateSelfContentLink(name, selfLink) {
    return {
      content: "{{il_0_" + name + "}}",
      links: [selfLink],
    }
  },
  AddParamsToContent(content) {
    for (let i = 1; i < arguments.length; i++) {
      content = content.replace(`{{param_${i - 1}}}`, arguments[i])
    }
    return content
  },
  GetDefaultCountry() {
    return "ES"
  },
  MergeFormValues(d1, d2) {
    if (!d1) {
      d1 = {}
    }
    if (d2) {
      for (let key in d2) {
        if (d2[key] !== "") {
          d1[key] = d2[key]
        }
      }
    }
    return d1
  },
  CreateSlug(text) {
    let slug = text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(/\W/g)
    if (slug.length > 9) {
      slug = slug.slice(0, 9)
    }
    return slug.join("-")
  },
  GetLowestShippingGroup(data) {
    if (data && data.pages && data.pages.length) {
      if (data.pages.length === 0) {
        return
      }
      return data.pages[0].sub_groups
    }
  },
  GetCountryFromForm(formValues) {
    if (formValues.shipping_country && formValues.shipping_country !== "") {
      return formValues.shipping_country
    }
    return formValues.country
  },
  CancelToken(cancelToken) {
    if (cancelToken) {
      cancelToken.cancel("x_sbf_axios_request_cancel")
    }
  },
  Unsubscribe(unsubscribe) {
    if (unsubscribe) {
      unsubscribe()
    }
  },
  LogAxiosError(err, noInternal) {
    if (!err) {
      return
    }

    // If noInternal = true, don't go to the internal error page nor reset the token
    if (err.message) {
      if (err.message === cancelMsg) {
        console.info("Request canceled")
        return true
      }
      if (err.response) {
        console.error("Request error: ", err.response.data)
        if (err.response.status === 401) {
          if (!noInternal) {
            tStore.dispatch(setT(""))
          }
          console.warn("Unauthorized: ", err.message)
          return
        }
        if (err.response.status === 404) {
          console.warn("Not found: ", err.message)
          navigate("/404")
          return
        }
        if (err.response.status === 503) {
          return
        }
      } else {
        console.error("Request error (msg): ", err.message)
      }
    } else {
      console.error("Unhandled Error: ", err)
    }

    if (!noInternal) {
      navigate("/50x")
    }
  },
  flatten(text, child) {
    return typeof child === "string"
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flatten, text)
  },
  HeadingRenderer(props) {
    let children = React.Children.toArray(props.children)
    let text = children.reduce(this.flatten, "")
    let slug = this.CreateSlug(text)
    let css = ""

    if (props.level == 2) {
      css = "text-center text-uppercase mt-4 mb-4"
    }

    if (props.level == 3) {
      css = "mt-2 mb-2"
    }

    return React.createElement(
      "h" + props.level,
      { id: slug, className: css },
      props.children
    )
  },
  LinkRenderer(props, css) {
    const site = "https://lottamae.com"
    if (props.href.startsWith(site)) {
      const intLink = props.href.replace(site, "")
      return (
        <Link className={css} to={intLink} title={intLink}>
          {props.children}
        </Link>
      )
    }
    return (
      <a className={css} href={props.href} title={props.href}>
        {props.children}
      </a>
    )
  },
  ListItemRenderer(props) {
    var children = React.Children.toArray(props.children)
    let css = "markdown-bullets"
    var text = children.reduce(flatten, "")
    if (
      text.startsWith("a.") ||
      text.startsWith("b.") ||
      text.startsWith("c.") ||
      text.startsWith("d.") ||
      text.startsWith("e.") ||
      text.startsWith("f.") ||
      text.startsWith("g.")
    ) {
      css = "markdown-no-bullets"
    }

    return <li className={css}>{props.children}</li>
  },
  ParagraphRenderer(props, css) {
    return <div className={css}>{props.children}</div>
  },
  GetMarkdown(content, css) {
    if (!css) {
      css = {}
    }
    return (
      <ReactMarkdown
        children={content}
        components={{
          // TODO: CSS for heading and list
          heading: this.HeadingRenderer,
          listItem: this.ListItemRenderer,
          link: props => {
            return this.LinkRenderer(props, css.link)
          },
          paragraph: props => {
            return this.ParagraphRenderer(props, css.paragraph)
          },

          h1: this.HeadingRenderer,
          h2: this.HeadingRenderer,
          h3: this.HeadingRenderer,
          h4: this.HeadingRenderer,
          h5: this.HeadingRenderer,
          h6: this.HeadingRenderer,
          li: this.ListItemRenderer,
          a: props => {
            return this.LinkRenderer(props, css.link)
          },
          p: props => {
            return this.ParagraphRenderer(props, css.paragraph)
          },
        }}
      />
    )
  },
  GetContentKeys(content, key, locale) {
    const retval = []
    for (let index in content) {
      const c = content[index]
      if (c.key === key) {
        if (!locale) {
          retval.push(c)
          continue
        }
        if (c.locale === locale) {
          retval.push(c)
          continue
        }
      }
    }
    return retval
  },
  GetContentKeyValues(content, key, locale) {
    const cKeys = this.GetContentKeys(content, key, locale)
    return cKeys.map(item => {
      return item.value
    })
  },
  GetCarouselImagesFromContent(content, key, caption, locale) {
    const altTexts = this.GetContentKeyValues(content, key + "_alt", locale)

    return this.GetContentKeyValues(content, key, locale).map((item, index) => {
      return {
        src: item,
        altText: altTexts[index],
        caption: caption,
      }
    })
  },
  GetContentKey(content, key, locale) {
    for (let index in content) {
      const c = content[index]
      if (c.key === key) {
        if (!locale) {
          return c
        }
        if (c.locale === locale) {
          return c
        }
      }
    }
  },
  GetContentKeyValue(content, key, locale) {
    const c = this.GetContentKey(content, key, locale)
    if (c) {
      return c.value
    }
  },
  GetContentKeyValueWithDefault(content, key, locale) {
    const c = this.GetContentKey(content, key, locale)
    if (c) {
      return c.value
    }
    const d = this.GetContentKey(content, key)
    if (d) {
      return d.value
    }
  },
  GetProductPrice(product) {
    if (
      product.data &&
      product.data.price &&
      product.data.price.value &&
      product.data.price.value !== ""
    ) {
      return product.data.price
    }

    return this.GetLowestVariationPrice(product.variation_sequences)
  },
  GetLowestVariationPrice(variationSequences) {
    let retval

    if (
      !variationSequences ||
      !variationSequences.length ||
      variationSequences.length === 0
    ) {
      return
    }

    for (let index in variationSequences) {
      const vs = variationSequences[index]

      if (vs.data && vs.data.price) {
        if (!retval || retval.value > vs.data.price.value) {
          retval = vs.data.price
        }
      }
    }

    return retval
  },
  GetProductQuantity(product) {
    if (product.data && product.data.quantity && product.data.quantity !== 0) {
      return product.data.quantity
    }

    return this.GetHighestVariationQuantity(product.variation_sequences)
  },
  GetHighestVariationQuantity(variationSequences) {
    let retval

    if (
      !variationSequences ||
      !variationSequences.length ||
      variationSequences.length === 0
    ) {
      return 0
    }

    for (let index in variationSequences) {
      const vs = variationSequences[index]

      if (vs.data && vs.data.quantity) {
        if (!retval || retval < vs.data.quantity) {
          retval = vs.data.quantity
        }
      }
    }

    if (retval) {
      return retval
    }

    return 0
  },
  CartToCheck(cart, country, shippingGroup, shippingSubGroup, discountCode) {
    let check = {}
    let products = []

    for (let i in cart) {
      const cartItem = cart[i]

      let newCartItem = {
        id: cartItem.id,
        data: {
          quantity: cartItem.quantity,
        },
      }

      if (cartItem.variations) {
        newCartItem.sequence = this.VariationMapToArray(cartItem.variations)
      }

      products.push(newCartItem)
    }

    check.products = products

    if (country) {
      let addToTotal

      if (shippingGroup && shippingSubGroup) {
        addToTotal = true
      }

      check.shipping = {
        country: {
          code: country,
        },
        shipping_group: shippingGroup,
        shipping_sub_group: shippingSubGroup,
        add_to_total: addToTotal,
      }
    }
    if (discountCode) {
      check.discount = {
        code: discountCode,
      }
    }

    return check
  },
  VariationMapToArray(pVariations) {
    let sequence = []

    for (let variationID in pVariations) {
      const optionID = pVariations[variationID]
      sequence.push({
        variation: {
          id: variationID,
        },
        option: {
          id: optionID,
        },
      })
    }
    return sequence
  },
  CreateQuantityCheckBody(id, pVariations, quantity) {
    return {
      products: [
        {
          id: id,
          sequence: this.VariationMapToArray(pVariations),
          data: {
            quantity: quantity,
          },
        },
      ],
    }
  },
  GetProducts(check, language, t, cb, errCb) {
    const cancelToken = axios.CancelToken.source()
    let query = ""

    if (!t) {
      console.warn("Cannot get products, not authenticated")
    }

    if (!check.products || check.products.length === 0) {
      return
    }

    if (language) {
      query += `?locale=${this.LanguageToLocale(language)}`
    }
    axios
      .post(
        `/enjocommerce/v1/get/products${query}`,
        check,
        this.RequestHeaders(t, cancelToken)
      )
      .then(data => {
        cb(data.data)
      })
      .catch(err => {
        if (errCb) {
          errCb(err)
          return
        }
        this.LogAxiosError(err)
      })
    return cancelToken
  },
  CheckProducts(check, language, t, cb, errCb) {
    const cancelToken = axios.CancelToken.source()
    let query = ""

    if (!t) {
      console.warn("Cannot check products, not authenticated")
    }

    if (!check.products || check.products.length === 0) {
      return
    }

    if (language) {
      query += `?locale=${this.LanguageToLocale(language)}`
    }
    axios
      .post(
        `/enjocommerce/v1/check/products${query}`,
        check,
        this.RequestHeaders(t, cancelToken)
      )
      .then(data => {
        cb(data.data)
      })
      .catch(err => {
        if (errCb) {
          errCb(err)
          return
        }
        this.LogAxiosError(err)
      })
    return cancelToken
  },
  GetAllProducts(language, t, cb, errCb) {
    const cancelToken = axios.CancelToken.source()
    let query = ""

    if (!t) {
      console.warn("Cannot get products, not authenticated")
    }

    if (language) {
      query += `?locale=${this.LanguageToLocale(language)}`
    }

    axios
      .get(
        `/enjocommerce/v1/products${query}`,
        this.RequestHeaders(t, cancelToken)
      )
      .then(data => {
        if (data.data.pages) {
          cb(data.data.pages)
        } else {
          cb([])
        }
      })
      .catch(err => {
        if (errCb) {
          errCb(err)
          return
        }
        this.LogAxiosError(err)
      })
    return cancelToken
  },
  GetMarkdownTextFromLink(markdown) {
    const groups = markdown.match(/\[([^)]+)\]/)
    if (groups.length >= 2) {
      return groups[1]
    }
  },
  GetMarkdownLinkFromLink(markdown) {
    const groups = markdown.match(/\(([^)]+)\)/)
    if (groups.length >= 2) {
      return groups[1]
    }
  },
  CreateProductKey(id, sequence) {
    let ids = [id]

    for (let key in sequence) {
      ids.push(key, sequence[key])
    }

    return ids.sort().join("")
  },
  CreateProductKeyFromList(id, sequence) {
    let ids = [id]

    for (let key in sequence) {
      const variationOption = sequence[key]
      ids.push(variationOption.variation.id, variationOption.option.id)
    }

    return ids.sort().join("")
  },
  FetchDiscount(code, t, cb) {
    if (!t) {
      console.warn("Cannot fetch discount, not authenticated")
      return
    }

    const cancelToken = axios.CancelToken.source()
    axios
      .get(
        `/enjocommerce/v1/discounts/codes/${code}`,
        this.RequestHeaders(t, cancelToken)
      )
      .then(data => {
        cb(data.data)
      })
      .catch(err => {
        if (!err.response || err.response.status !== 404) {
          if (this.LogAxiosError(err)) {
            return
          }
        }
        cb()
      })
    return cancelToken
  },
  CartExists() {
    let existingCart = this.GetCart("cart-items")

    if (existingCart) {
      if (!existingCart.length) {
        localStorage.removeItem("cart-items")
        return false
      } else if (existingCart.length && existingCart.length !== 0) {
        return true
      }
    }
    return false
  },
  CreateOrderBody(
    products,
    formValues,
    shippingGroup,
    shippingSubGroup,
    discount,
    extraShipping,
    locale
  ) {
    const reqProducts = products.map(item => {
      return {
        id: item.id,
        data: {
          quantity: item.quantity,
        },
        sequence: item.sequence,
      }
    })
    const order = {
      email: formValues.email,
      content: [],
      products: reqProducts,
      locale: locale,
      payment_method: formValues.payment_method,
      discount: discount,
      address: {},
      shipping: {
        shipping_group: shippingGroup,
        shipping_sub_group: shippingSubGroup,
      },
    }
    if (formValues.first_name) {
      order.content.push({
        key: "first_name",
        value: formValues.first_name,
        content_type: "text/plain",
      })
    }

    if (formValues.last_name) {
      order.content.push({
        key: "last_name",
        value: formValues.last_name,
        content_type: "text/plain",
      })
    }

    if (formValues.phone) {
      let countryCode = ""
      if (formValues.phone_country_code) {
        countryCode = formValues.phone_country_code
      }
      order.content.push({
        key: "phone",
        value: countryCode + formValues.phone,
        content_type: "text/plain",
      })
    }

    if (formValues.company_name) {
      order.content.push({
        key: "company_name",
        value: formValues.company_name,
        content_type: "text/plain",
      })
    }

    if (formValues.vat) {
      order.content.push({
        key: "vat",
        value: formValues.vat,
        content_type: "text/plain",
      })
    }

    if (formValues.country) {
      order.address.country = {
        code: formValues.country,
      }
    }

    order.address.content = []
    if (formValues.street) {
      order.address.content.push({
        key: "street",
        value: formValues.street,
      })
    }

    if (formValues.house_number) {
      order.address.content.push({
        key: "house_number",
        value: formValues.house_number,
      })
    }

    if (formValues.house_number_extra) {
      order.address.content.push({
        key: "house_number_extra",
        value: formValues.house_number_extra,
      })
    }

    if (formValues.postal_code) {
      order.address.content.push({
        key: "postal_code",
        value: formValues.postal_code,
      })
    }

    if (formValues.city) {
      order.address.content.push({
        key: "city",
        value: formValues.city,
      })
    }

    if (formValues.province) {
      order.address.content.push({
        key: "province",
        value: formValues.province,
      })
    }

    if (formValues.newsletter) {
      order.newsletter_subscribed = true
    }

    if (extraShipping) {
      order.shipping_address = {}
      order.shipping_address.content = []

      if (formValues.shipping_country) {
        order.shipping_address.country = {
          code: formValues.shipping_country,
        }
      }

      if (formValues.attention_of) {
        order.shipping_address.content.push({
          key: "attention_of",
          value: formValues.attention_of,
        })
      }

      if (formValues.shipping_street) {
        order.shipping_address.content.push({
          key: "street",
          value: formValues.shipping_street,
        })
      }

      if (formValues.shipping_house_number) {
        order.shipping_address.content.push({
          key: "house_number",
          value: formValues.shipping_house_number,
        })
      }

      if (formValues.shipping_house_number_extra) {
        order.shipping_address.content.push({
          key: "house_number_extra",
          value: formValues.shipping_house_number_extra,
        })
      }

      if (formValues.shipping_postal_code) {
        order.shipping_address.content.push({
          key: "postal_code",
          value: formValues.shipping_postal_code,
        })
      }

      if (formValues.shipping_city) {
        order.shipping_address.content.push({
          key: "city",
          value: formValues.shipping_city,
        })
      }

      if (formValues.shipping_province) {
        order.shipping_address.content.push({
          key: "province",
          value: formValues.shipping_province,
        })
      }
    }

    return order
  },
  ModStat(t, id, isWishlist, decrement) {
    if (!t) {
      console.warn("Cannot update stats, not authenticated")
      return
    }

    try {
      const cancelToken = axios.CancelToken.source()
      const obj = isWishlist ? "wishlist" : "cart"
      const oper = decrement ? "decrement" : "increment"

      axios
        .post(
          `/enjocommerce/v1/stats/${obj}/${oper}`,
          { id: id },
          this.RequestHeaders(t, cancelToken)
        )
        .then(() => {})
        .catch(err => {
          // Don't go to 500 page
          console.error(err)
        })
      return cancelToken
    } catch (err) {
      console.error(err)
    }
  },
  DoGoogleTagEvent(tagManagerArgs) {
    try {
      const cookies = new Cookies()
      if (cookies.get("sbf-gdpr", { path: "/" }) !== "true") {
        return
      }

      tagManagerArgs.gtmId = gtmID
      tagManagerArgs.dataLayerName = "dataLayer"
      TagManager.dataLayer(tagManagerArgs)
    } catch {}
  },
  GetKeywordsFromTags(tags, language) {
    if (tags) {
      return tags.map(item => {
        return this.GetContentKeyValue(item.content, "name", language)
      })
    }

    return []
  },
}
