import React, { Component } from "react"

export default class Notification extends Component {
  render() {
    const { iconClass, text } = this.props
    return (
      <div className="container notification">
        <div className="container mt-4 mb-4">
          <div className="row">
            <div className="col-2">
              <i className={iconClass} />
            </div>
            <div className="col-10 text-uppercase text-wrap">{text}</div>
          </div>
        </div>
      </div>
    )
  }
}
