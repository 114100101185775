import React from "react"
import { Component } from "react"
import ReactLoading from "react-loading"
import Utils from "../../utils/node.utils"

export default class Loading extends Component {
  render() {
    let { className } = this.props
    className = " " + className

    return (
      <ReactLoading
        className={"mx-auto my-auto" + className}
        type="spokes"
        color="#000000"
        height={300}
        width={100}
      />
    )
  }
}
