exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-50-x-js": () => import("./../../../src/pages/50x.js" /* webpackChunkName: "component---src-pages-50-x-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessories-js": () => import("./../../../src/pages/accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-es-about-js": () => import("./../../../src/pages/es/about.js" /* webpackChunkName: "component---src-pages-es-about-js" */),
  "component---src-pages-es-accessories-js": () => import("./../../../src/pages/es/accessories.js" /* webpackChunkName: "component---src-pages-es-accessories-js" */),
  "component---src-pages-es-blog-js": () => import("./../../../src/pages/es/blog.js" /* webpackChunkName: "component---src-pages-es-blog-js" */),
  "component---src-pages-es-cart-js": () => import("./../../../src/pages/es/cart.js" /* webpackChunkName: "component---src-pages-es-cart-js" */),
  "component---src-pages-es-checkout-js": () => import("./../../../src/pages/es/checkout.js" /* webpackChunkName: "component---src-pages-es-checkout-js" */),
  "component---src-pages-es-confirm-js": () => import("./../../../src/pages/es/confirm.js" /* webpackChunkName: "component---src-pages-es-confirm-js" */),
  "component---src-pages-es-contact-js": () => import("./../../../src/pages/es/contact.js" /* webpackChunkName: "component---src-pages-es-contact-js" */),
  "component---src-pages-es-cookies-js": () => import("./../../../src/pages/es/cookies.js" /* webpackChunkName: "component---src-pages-es-cookies-js" */),
  "component---src-pages-es-faq-js": () => import("./../../../src/pages/es/faq.js" /* webpackChunkName: "component---src-pages-es-faq-js" */),
  "component---src-pages-es-guarantee-js": () => import("./../../../src/pages/es/guarantee.js" /* webpackChunkName: "component---src-pages-es-guarantee-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-jewelry-js": () => import("./../../../src/pages/es/jewelry.js" /* webpackChunkName: "component---src-pages-es-jewelry-js" */),
  "component---src-pages-es-order-js": () => import("./../../../src/pages/es/order.js" /* webpackChunkName: "component---src-pages-es-order-js" */),
  "component---src-pages-es-privacy-js": () => import("./../../../src/pages/es/privacy.js" /* webpackChunkName: "component---src-pages-es-privacy-js" */),
  "component---src-pages-es-shipping-js": () => import("./../../../src/pages/es/shipping.js" /* webpackChunkName: "component---src-pages-es-shipping-js" */),
  "component---src-pages-es-shop-js": () => import("./../../../src/pages/es/shop.js" /* webpackChunkName: "component---src-pages-es-shop-js" */),
  "component---src-pages-es-terms-js": () => import("./../../../src/pages/es/terms.js" /* webpackChunkName: "component---src-pages-es-terms-js" */),
  "component---src-pages-es-unsubscribe-js": () => import("./../../../src/pages/es/unsubscribe.js" /* webpackChunkName: "component---src-pages-es-unsubscribe-js" */),
  "component---src-pages-es-wishlist-js": () => import("./../../../src/pages/es/wishlist.js" /* webpackChunkName: "component---src-pages-es-wishlist-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-guarantee-js": () => import("./../../../src/pages/guarantee.js" /* webpackChunkName: "component---src-pages-guarantee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jewelry-js": () => import("./../../../src/pages/jewelry.js" /* webpackChunkName: "component---src-pages-jewelry-js" */),
  "component---src-pages-nl-about-js": () => import("./../../../src/pages/nl/about.js" /* webpackChunkName: "component---src-pages-nl-about-js" */),
  "component---src-pages-nl-accessories-js": () => import("./../../../src/pages/nl/accessories.js" /* webpackChunkName: "component---src-pages-nl-accessories-js" */),
  "component---src-pages-nl-blog-js": () => import("./../../../src/pages/nl/blog.js" /* webpackChunkName: "component---src-pages-nl-blog-js" */),
  "component---src-pages-nl-cart-js": () => import("./../../../src/pages/nl/cart.js" /* webpackChunkName: "component---src-pages-nl-cart-js" */),
  "component---src-pages-nl-checkout-js": () => import("./../../../src/pages/nl/checkout.js" /* webpackChunkName: "component---src-pages-nl-checkout-js" */),
  "component---src-pages-nl-confirm-js": () => import("./../../../src/pages/nl/confirm.js" /* webpackChunkName: "component---src-pages-nl-confirm-js" */),
  "component---src-pages-nl-contact-js": () => import("./../../../src/pages/nl/contact.js" /* webpackChunkName: "component---src-pages-nl-contact-js" */),
  "component---src-pages-nl-cookies-js": () => import("./../../../src/pages/nl/cookies.js" /* webpackChunkName: "component---src-pages-nl-cookies-js" */),
  "component---src-pages-nl-faq-js": () => import("./../../../src/pages/nl/faq.js" /* webpackChunkName: "component---src-pages-nl-faq-js" */),
  "component---src-pages-nl-guarantee-js": () => import("./../../../src/pages/nl/guarantee.js" /* webpackChunkName: "component---src-pages-nl-guarantee-js" */),
  "component---src-pages-nl-index-js": () => import("./../../../src/pages/nl/index.js" /* webpackChunkName: "component---src-pages-nl-index-js" */),
  "component---src-pages-nl-jewelry-js": () => import("./../../../src/pages/nl/jewelry.js" /* webpackChunkName: "component---src-pages-nl-jewelry-js" */),
  "component---src-pages-nl-order-js": () => import("./../../../src/pages/nl/order.js" /* webpackChunkName: "component---src-pages-nl-order-js" */),
  "component---src-pages-nl-privacy-js": () => import("./../../../src/pages/nl/privacy.js" /* webpackChunkName: "component---src-pages-nl-privacy-js" */),
  "component---src-pages-nl-shipping-js": () => import("./../../../src/pages/nl/shipping.js" /* webpackChunkName: "component---src-pages-nl-shipping-js" */),
  "component---src-pages-nl-shop-js": () => import("./../../../src/pages/nl/shop.js" /* webpackChunkName: "component---src-pages-nl-shop-js" */),
  "component---src-pages-nl-terms-js": () => import("./../../../src/pages/nl/terms.js" /* webpackChunkName: "component---src-pages-nl-terms-js" */),
  "component---src-pages-nl-unsubscribe-js": () => import("./../../../src/pages/nl/unsubscribe.js" /* webpackChunkName: "component---src-pages-nl-unsubscribe-js" */),
  "component---src-pages-nl-wishlist-js": () => import("./../../../src/pages/nl/wishlist.js" /* webpackChunkName: "component---src-pages-nl-wishlist-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-shipping-js": () => import("./../../../src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

