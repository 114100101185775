import React, { Component } from "react"
import Utils from "../../../utils/node.utils"

export default class Form extends Component {
  state = {
    id: "",
    variations: {},
  }

  componentDidMount() {
    const { product, language } = this.props

    this.setState({
      id: product.id,
    })

    if (product.variations === null) {
      return
    }

    if (product.variations) {
      const variations = Utils.GetVariationVars(
        product.variation_sequences,
        product.variations,
        language
      )
      this.setState({
        variations: variations,
      })
    }
  }

  componentDidUpdate() {
    const { id } = this.state
    const { product, language } = this.props

    if (id === product.id) {
      return
    } else {
      if (product.variations) {
        const variations = Utils.GetVariationVars(
          product.variation_sequences,
          product.variations,
          language
        )
        this.setState({
          id: product.id,
          variations: variations,
        })
      } else {
        this.setState({
          id: product.id,
          variations: {},
        })
      }
    }
  }

  createOptions = () => {
    const {
      contentMap,
      setCartDisabled,
      selectVariation,
      language,
    } = this.props
    const { variations } = this.state

    let retVal = []

    for (let variation in variations) {
      const variationObject = variations[variation]
      const options = variationObject["options"]

      if (Utils.IsInputVariation(variationObject)) {
        retVal.push(
          <CustomTextOptions
            key={variation}
            variationKey={variation}
            variation={variationObject}
            selectVariation={selectVariation}
            id={variationObject.id}
            contentMap={contentMap}
            setCartDisabled={setCartDisabled}
            language={language}
          />
        )
        continue
      }

      if (Object.keys(options).length > 6) {
        retVal.push(
          <DropdownOptions
            key={variation}
            variationKey={variation}
            variation={variationObject}
            options={options}
            selectVariation={selectVariation}
            id={variationObject.id}
            contentMap={contentMap}
            setCartDisabled={setCartDisabled}
            language={language}
          />
        )
        continue
      }

      retVal.push(
        <SelectOptions
          key={variation}
          variationKey={variation}
          variation={variationObject}
          options={options}
          selectVariation={selectVariation}
          id={variationObject.id}
          contentMap={contentMap}
          setCartDisabled={setCartDisabled}
          language={language}
        />
      )
    }
    return retVal
  }

  onSubmit(event) {
    event.preventDefault()
  }

  render() {
    const { product, noForm } = this.props

    if (product.variations === null) {
      return null
    }

    if (noForm) {
      return (
        <div action="#" onSubmit={this.onSubmit}>
          {this.createOptions()}
        </div>
      )
    }

    return (
      <form action="#" onSubmit={this.onSubmit}>
        {this.createOptions()}
      </form>
    )
  }
}

class SelectOptions extends Component {
  state = {
    name: "",
    options: [],
  }

  componentDidMount() {
    const {
      id,
      variationKey,
      variation,
      options,
      selectVariation,
      setCartDisabled,
      language,
    } = this.props
    const sortedOptions = Object.keys(options).sort()
    const stateOptions = sortedOptions.map(item => {
      return {
        id: options[item],
        name: item,
      }
    })

    let defaultOptionID

    if (stateOptions.length !== 0) {
      defaultOptionID = stateOptions[0].id
      selectVariation(id, defaultOptionID)
      setCartDisabled(false, variationKey)
    }
    this.setState({
      name: getName(variationKey, variation, language),
      options: stateOptions,
      selectedOption: defaultOptionID,
    })
  }

  onClick = optionID => {
    const { id, selectVariation } = this.props
    selectVariation(id, optionID)
    this.setState({
      selectedOption: optionID,
    })
  }

  render() {
    const { contentMap } = this.props
    const { name, options, selectedOption } = this.state

    return (
      <div className="row">
        <div className="col-sm-6 col-lg-12 detail-option mb-3">
          <h6 className="detail-option-heading">
            {name + " "}
            <span>({contentMap.required.value})</span>
          </h6>
          {createOptions(options, selectedOption, this.onClick)}
        </div>
      </div>
    )
  }
}

class DropdownOptions extends Component {
  state = {
    name: "",
    options: [],
  }

  componentDidMount() {
    const {
      id,
      variationKey,
      variation,
      options,
      selectVariation,
      setCartDisabled,
      language,
    } = this.props
    const sortedOptions = Object.keys(options).sort()
    const stateOptions = sortedOptions.map(item => {
      return {
        id: options[item],
        name: item,
      }
    })

    let defaultOptionID
    if (stateOptions.length !== 0) {
      defaultOptionID = stateOptions[0].id
      selectVariation(id, defaultOptionID)
      setCartDisabled(false, variationKey)
    }

    this.setState({
      name: getName(variationKey, variation, language),
      options: stateOptions,
      selectedOption: defaultOptionID,
    })
  }

  onClick = optionID => {
    const { id, selectVariation } = this.props
    selectVariation(id, optionID.target.value)
    this.setState({
      selectedOption: optionID.target.value,
    })
  }

  render() {
    const { variationKey, contentMap } = this.props
    const { name, options, selectedOption } = this.state

    return (
      <div className="row">
        <div className="col-sm-6 col-lg-12 detail-option mb-3">
          <h6 className="detail-option-heading">
            {name} <span>({contentMap.required.value})</span>
          </h6>
          <select
            id={variationKey}
            name={variationKey}
            value={selectedOption}
            className="col-12 form-control"
            onChange={this.onClick}
          >
            {getDropDownOptions(options, selectedOption)}
          </select>
        </div>
      </div>
    )
  }
}

class CustomTextOptions extends Component {
  // TODO: Review this component
  // There is still content coming from the shop page to display the info text and error text
  state = {
    name: "",
    value: "",
    minLength: 0,
    maxLength: 0,
    set: "",
    caseSensitive: false,
    labelCSS: "",
    inputCSS: "form-control col-6 ml-3 mb-3",
    extraText: "",
    invalid: true,
  }

  componentDidMount() {
    const {
      id,
      variationKey,
      variation,
      setCartDisabled,
      selectVariation,
      language,
    } = this.props
    const { value } = this.state

    setCartDisabled(true, variationKey)

    this.setState({
      name: getName(variationKey, variation, language),
      set: variation.input.charset,
      minLength: variation.input.min_characters,
      maxLength: variation.input.max_characters,
    })

    selectVariation(id, "Custom:" + value)
  }

  outOfSet(value) {
    const { set, caseSensitive } = this.state
    if (set === "") {
      return
    }
    if (!caseSensitive) {
      value = value.toLowerCase()
    }

    for (var i = 0; i < value.length; i++) {
      const cAt = value.charAt(i)
      if (set.indexOf(cAt) === -1) {
        return cAt.toUpperCase()
      }
    }
    return
  }

  reset = () => {
    const { id, selectVariation } = this.props
    this.setState({
      value: "",
    })
    selectVariation(id)
  }

  handleChange = event => {
    const {
      id,
      variationKey,
      selectVariation,
      setCartDisabled,
      contentMap,
    } = this.props
    const { minLength, maxLength, caseSensitive } = this.state

    let value = event.target.value
    let invalidLength = false

    if (maxLength != 0 && value.length > maxLength) {
      return
    }

    this.setState({
      labelCSS: "",
      inputCSS: "form-control col-6 ml-3 mb-3",
      extraText: "",
      invalid: false,
    })

    const invalidChar = this.outOfSet(value)
    if (invalidChar) {
      this.setState({
        extraText: Utils.AddParamsToContent(
          contentMap.error_personalization_1.value,
          invalidChar
        ),
      })
    }

    if (minLength != 0 && value.length < minLength) {
      this.setState({
        extraText: Utils.AddParamsToContent(
          contentMap.error_personalization_2.value,
          minLength
        ),
      })
      invalidLength = true
    }

    if (invalidChar || invalidLength) {
      setCartDisabled(true, variationKey)
      this.setState({
        labelCSS: "text-danger",
        inputCSS: "form-control col-6 ml-3 mb-3 forminput-invalid",
        invalid: true,
      })
    } else {
      setCartDisabled(false, variationKey)
    }

    if (!caseSensitive) {
      value = value.toUpperCase()
    }

    selectVariation(id, "Custom:" + value)
    this.setState({
      value: value,
    })
  }

  render() {
    const { contentMap, variationKey } = this.props
    const { name, value, labelCSS, extraText, inputCSS } = this.state

    return (
      <div className="">
        <div className="row d-flex align-items-center">
          <label className="col-12 detail-option" htmlFor={variationKey}>
            <h6 className="detail-option-heading">
              {name} <span>({contentMap.required.value})</span>
            </h6>
            {contentMap.text_personalization.value}
            <div className={labelCSS}>{extraText}</div>
          </label>
        </div>
        <div className="row d-flex align-items-center">
          <input
            className={inputCSS}
            type="text"
            id={variationKey}
            value={value}
            onChange={this.handleChange}
            placeholder="Text"
            aria-label={variationKey}
            name={variationKey}
          />
        </div>
      </div>
    )
  }
}

function createOptions(options, selectedOptionID, onClick) {
  return options.map(item => {
    return (
      <Option
        id={item.id}
        name={item.name}
        key={item.name}
        onClick={onClick}
        checked={selectedOptionID === item.id}
      />
    )
  })
}

class Option extends Component {
  onChange = () => {
    const { id, onClick } = this.props
    onClick(id)
  }

  render() {
    const { id, name, checked } = this.props

    let css = "btn btn-product-left btn-variants-margin-right btn-outline-dark"
    if (checked) {
      css = "btn btn-product-left btn-variants-margin-right btn-dark"
    }

    return (
      <label htmlFor={id} key={id} className={css}>
        {name}
        <input
          type="radio"
          name={name}
          id={id}
          value={id}
          required
          className="input-invisible"
          aria-label={name}
          checked={checked}
          onChange={this.onChange}
        />
      </label>
    )
  }
}

function getDropDownOptions(options, selectedOption) {
  return options.map(item => {
    return (
      <option id={item.id} value={item.id} key={item.id}>
        {item.name}
      </option>
    )
  })
}

function getName(variationKey, variation, language) {
  const name = Utils.GetContentKeyValue(
    variation.content,
    "name",
    Utils.LanguageToLocale(language)
  )

  if (!name || name === "") {
    return variationKey
  }
  return name
}
