import React, { Component } from "react"
import Newsletter from "../Newsletter/Newsletter"
import { Store } from "react-notifications-component"

export default class NewsletterNotification extends Component {
  removeSelf = () => {
    Store.removeNotification(this.props.id)
  }

  render() {
    const { commonContentMap, language } = this.props

    return (
      <div className="container ns-notification">
        <Newsletter
          commonContentMap={commonContentMap}
          removeNotification={this.removeSelf}
          showTitle={true}
          mCss={"mt-4 mb-4"}
          prefix="notf_"
          language={language}
        />
      </div>
    )
  }
}
