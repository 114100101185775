import React, { Component } from "react"
import { Store } from "react-notifications-component"
import Utils from "../../../utils/node.utils"
import { Link } from "gatsby"

export default class ShippingNotification extends Component {
  removeSelf = event => {
    event.preventDefault()
    Store.removeNotification(this.props.id)
  }

  getCountryName = () => {
    const { country, countries } = this.props

    for (let i in countries) {
      const c = countries[i]
      if (c.code === country) {
        return c.info[0].name
      }
    }

    return ""
  }

  render() {
    const {
      contentMap,
      commonContentMap,
      language,
      shippingPrice,
      currency,
    } = this.props

    const notification = contentMap.notification_shipping.value
      .replace(
        "{price}",
        Utils.GetCurrencySymbol(currency) + " " + shippingPrice.toFixed(2)
      )
      .replace("{country}", this.getCountryName())

    return (
      <div className="container shipping-notification">
        <div className={"container mt-4 mb-4"}>
          <div className={"row"}>
            <div className="col-2 text-responsive-size text-uppercase">
              {/* <i className="fa fa-gift"></i> */}
              {/* <i className="fa fa-gifts"></i> */}
              {/* <i className="fa fa-star"></i> */}
              <i className="fa fa-truck"></i>
            </div>
            <div className="col-8 text-uppercase my-auto">{notification}</div>
            <div className="col-2 text-right">
              <a
                href=""
                title={commonContentMap.close.value}
                className="exit-ns-toggler"
                onClick={this.removeSelf}
              >
                <i className="fa fa-window-close" />
              </a>
            </div>
          </div>
        </div>
        <div className={"container mb-4"}>
          <div className={"row"}>
            <Link
              to={Utils.GetLanguageLink(language, "/shop")}
              title={contentMap.notification_shop_more.value}
              className="col-12 btn btn-pink"
              role="button"
            >
              {contentMap.notification_shop_more.value}
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
