import React, { Component } from "react"
import Utils from "../../../utils/node.utils"
import axios from "axios"
import { store as tStore, setT, setMM, setMC, setTB } from "../../Store/Store"
import Loading from "../../Loading/Loading"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker"
import nl from "date-fns/locale/nl"
import Form from "../../ProductDetail/Form/Form"

registerLocale("nl", nl)

export default class Bulk extends Component {
  state = {
    t: "",
    submitted: false,
    countries: [],
    countriesLoaded: false,
    products: [],
    productsLoaded: false,
    selectedVariations: {},
    formValues: {
      question_email: "",
      question_first_name: "",
      question_product: "",
      question_personalisation: "",
      question_quantity: "",
      question_delivery_date: "",
      question_country: "",
      question_comment: "",
      answer_email: "",
      answer_first_name: "",
      answer_product: "",
      answer_product_id: "",
      answer_quantity: "",
      answer_personalisation: "",
      answer_personalisation_id: "",
      answer_delivery_date: "",
      answer_delivery_date_picker: new Date(),
      answer_country: "ES",
      answer_comment: "",
      validation: "",
    },
    startDate: new Date(),
    errors: [],
  }

  componentDidMount() {
    const { formValues } = this.state
    const { contentMap } = this.props
    const now = new Date()
    now.setDate(now.getDate() + 7)

    formValues.question_email = contentMap.question_email.value
    formValues.question_first_name = contentMap.question_first_name.value
    formValues.question_product = contentMap.question_product.value
    formValues.question_personalisation =
      contentMap.question_personalisation.value
    formValues.question_delivery_date = contentMap.question_delivery_date.value
    formValues.question_quantity = contentMap.question_quantity.value
    formValues.question_country = contentMap.question_country.value
    formValues.question_comment = contentMap.question_comment.value

    formValues.answer_delivery_date_picker = now

    this.setState({
      formValues: formValues,
      startDate: now,
    })

    this.unsubscribe = tStore.subscribe(this.storeUpdate)
    this.storeUpdate()
  }

  componentWillUnmount() {
    Utils.CancelToken(this.cancelToken)
    Utils.CancelToken(this.countryCancelToken)
    Utils.CancelToken(this.productCancelToken)
    Utils.Unsubscribe(this.unsubscribe)
  }

  storeUpdate = () => {
    const storeState = tStore.getState()
    this.setState({
      t: storeState.t,
    })
  }

  storeUpdate = () => {
    const { t } = this.state
    const storeState = tStore.getState()

    if (storeState.t && storeState.t !== t) {
      this.setState(
        {
          t: storeState.t,
        },
        this.loadInitialData
      )
    }
  }

  loadInitialData = () => {
    const { countriesLoaded, productsLoaded, t } = this.state

    if (!t) {
      console.warn("Cannot init, not authenticated")
      return
    }

    if (!countriesLoaded) {
      this.fetchCountries()
      this.setState({
        countriesLoaded: true,
      })
    }
    if (!productsLoaded) {
      this.fetchProducts()
      this.setState({
        productsLoaded: true,
      })
    }
  }

  fetchCountries = () => {
    const { language } = this.props
    const { t } = this.state
    if (!t) {
      console.warn("Cannot fetch countries, not authenticated")
      return
    }
    this.countryCancelToken = Utils.FetchCountries(
      language,
      t,
      this.setCountries
    )
  }

  fetchProducts = () => {
    const { language } = this.props
    const { t } = this.state
    if (!t) {
      console.warn("Cannot fetch products, not authenticated")
      return
    }

    this.productCancelToken = Utils.GetAllProducts(
      language ? language : "en",
      t,
      this.setProducts
    )
  }

  setProducts = products => {
    const { formValues } = this.state
    let product_name, product_id

    for (let i in products) {
      const p = products[i]

      if (p.key === "Personalized Embroidered Bracelet") {
        product_name = Utils.GetContentKeyValue(p.content, "name")
        product_id = p.id
        break
      }
    }

    formValues.answer_product = product_name
    formValues.answer_product_id = product_id

    this.setState({
      formValues: formValues,
      products: products,
    })
  }

  setCountries = countries => {
    const { onCountriesChange } = this.props

    this.setState({
      countries: countries,
    })
    if (onCountriesChange) {
      onCountriesChange(countries)
    }
  }

  handleChange = event => {
    const { formValues, errors } = this.state

    switch (event.target.name) {
      case "answer_product":
        const optionElement =
          event.target.childNodes[event.target.selectedIndex]

        formValues[event.target.name + "_id"] = optionElement.id
        formValues[event.target.name] = event.target.value
        break
      default:
        formValues[event.target.name] = event.target.value
    }

    this.setState({
      formValues: formValues,
      errors: errors.filter(item => {
        return item !== event.target.name
      }),
    })
  }

  validateForm = () => {
    const { formValues, errors } = this.state

    if (formValues.validation !== "") errors.push("bulk_validation")

    if (!Utils.ValidateEmail(formValues.answer_email))
      errors.push("answer_email")
    if (formValues.answer_first_name === "") errors.push("answer_first_name")
    if (formValues.answer_product === "") errors.push("answer_product")
    if (formValues.answer_product_id === "") errors.push("answer_product")
    if (formValues.answer_quantity === "") errors.push("answer_quantity")
    if (formValues.answer_delivery_date === "")
      errors.push("answer_delivery_date")
    if (formValues.answer_country === "") errors.push("answer_country")
    if (formValues.validation !== "") errors.push("validation")
  }

  createFormBody = () => {
    const { language } = this.props
    const { formValues } = this.state

    const locale = Utils.LanguageToLocale(language)

    return {
      key: "bulk",
      content: [
        {
          key: "question_email",
          value: formValues.question_email,
          locale: locale,
        },
        {
          key: "answer_email",
          value: formValues.answer_email,
          locale: locale,
        },
        {
          key: "question_first_name",
          value: formValues.question_first_name,
          locale: locale,
        },
        {
          key: "answer_first_name",
          value: formValues.answer_first_name,
          locale: locale,
        },
        {
          key: "question_product",
          value: formValues.question_product,
          locale: locale,
        },
        {
          key: "answer_product",
          value: formValues.answer_product,
          locale: locale,
        },
        {
          key: "answer_product_id",
          value: formValues.answer_product_id,
          locale: locale,
        },
        {
          key: "question_quantity",
          value: formValues.question_quantity,
          locale: locale,
        },
        {
          key: "answer_quantity",
          value: formValues.answer_quantity,
          locale: locale,
        },
        {
          key: "question_personalisation",
          value: formValues.question_personalisation,
          locale: locale,
        },
        {
          key: "answer_personalisation",
          value: formValues.answer_personalisation,
          locale: locale,
        },
        {
          key: "answer_personalisation_id",
          value: formValues.answer_personalisation_id,
          locale: locale,
        },
        {
          key: "question_delivery_date",
          value: formValues.question_delivery_date,
          locale: locale,
        },
        {
          key: "answer_delivery_date",
          value: formValues.answer_delivery_date,
          locale: locale,
        },
        {
          key: "question_country",
          value: formValues.question_country,
          locale: locale,
        },
        {
          key: "answer_country",
          value: formValues.answer_country,
          locale: locale,
        },
        {
          key: "question_comment",
          value: formValues.question_comment,
          locale: locale,
        },
        {
          key: "answer_comment",
          value: formValues.answer_comment,
          locale: locale,
        },
      ].map((item, index) => {
        item.index = index + 1
        return item
      }),
    }
  }

  getPersonalisationObject = () => {
    const { selectedVariations } = this.state
    const product = this.getCurrentProduct()
    const variations = {}
    const options = {}

    if (!product.variations || product.variations.length === 0) {
      return {
        id: "",
        name: "",
      }
    }

    let entries = []
    let idEntries = []

    for (let i in product.variations) {
      const v = product.variations[i].variation

      variations[v.id] = v

      if (v.options && v.options.length !== 0) {
        for (let i in v.options) {
          const o = v.options[i]

          options[o.id] = o
        }
      }
    }

    for (let variationID in selectedVariations) {
      const optionID = selectedVariations[variationID]

      const optionStr = options[optionID]
        ? Utils.GetContentKeyValue(options[optionID].content, "name")
        : optionID

      entries.push(
        Utils.GetContentKeyValue(variations[variationID].content, "name") +
          ": " +
          optionStr
      )
      idEntries.push(variationID + ":" + optionID)
    }

    return {
      id: idEntries.join("|"),
      name: entries.join(" | "),
    }
  }

  onSubmit = event => {
    const { errors, t, formValues } = this.state
    const { contentMap, removeNotification, showTitle } = this.props
    const personalisation = this.getPersonalisationObject()

    event.preventDefault()

    formValues.answer_delivery_date = formValues.answer_delivery_date_picker
      .toISOString()
      .slice(0, 10)
    formValues.answer_personalisation = personalisation.name
    formValues.answer_personalisation_id = personalisation.id

    this.validateForm()

    if (errors.length !== 0) {
      this.setState({
        errors: errors,
      })
      return
    }

    this.setState({
      loadingForm: true,
    })

    const body = this.createFormBody()
    // TODO: there was a reset here in newsletter
    this.setState({
      formValues: formValues,
    })

    this.cancelToken = axios.CancelToken.source()

    axios
      .post(
        `/enjocommerce/v1/forms`,
        body,
        Utils.RequestHeaders(t, this.cancelToken)
      )
      .then(() => {
        Utils.DisplayNotification(
          "fa fa-truck",
          contentMap.notification_submitted.value
        )
        tStore.dispatch(setT(""))
        this.setState(
          {
            submitted: true,
            loadingForm: showTitle,
          },
          removeNotification
        )
      })
      .catch(err => {
        this.setState({
          loadingForm: false,
        })

        if (Utils.LogAxiosError(err)) {
          return
        }
        if (err.response) {
          if (err.response.status == 401) {
            tStore.dispatch(setT(""))
          }
        }
      })
  }

  removeNotification = () => {
    if (removeNotification) {
      removeNotification()
    }
  }

  onClick = event => {
    const { removeNotification } = this.props

    event.preventDefault()
    if (removeNotification) {
      removeNotification()
    }
  }

  errorCss = (id, css) => {
    const { errors } = this.state
    if (errors && errors.includes(id)) {
      css += " forminput-invalid"
    }
    return css
  }

  onMouseMove = () => {
    const { mm, submitted } = this.state
    if (mm || submitted) {
      return
    }
    tStore.dispatch(setMM(true))
    this.setState({
      mm: true,
    })
  }

  onMouseClick = () => {
    const { mc, submitted } = this.state
    if (mc || submitted) {
      return
    }
    tStore.dispatch(setMC(true))
    this.setState({
      mc: true,
    })
  }

  onKeyDown = event => {
    const { tb, submitted } = this.state

    if (tb || submitted) {
      return
    }

    if (event) {
      if (event.key === "Tab") {
        tStore.dispatch(setTB(true))
        this.setState({
          tb: true,
        })
      }
    }
  }

  prefixID = id => {
    const { prefix } = this.props
    if (prefix) {
      return prefix + id
    }
    return id
  }

  confirmDisabled = () => {
    const { formValues } = this.state

    return (
      formValues.answer_email === "" ||
      formValues.answer_first_name === "" ||
      formValues.answer_product === "" ||
      formValues.answer_product_id === "" ||
      formValues.answer_quantity === ""
    )
  }

  outOfStock = product => {
    if (product.data && product.data.quantity && product.data.quantity !== 0) {
      return false
    }

    if (product.variation_sequences) {
      for (let i in product.variation_sequences) {
        const s = product.variation_sequences[i]

        if (s.data && s.data.quantity && s.data.quantity !== 0) {
          return false
        }
      }
    }

    return true
  }

  createProductOptions = () => {
    const { products } = this.state

    return products
      .filter(item => {
        if (item.private) {
          return false
        }

        if (this.outOfStock(item)) {
          return false
        }
        return true
      })
      .sort((a, b) => {
        const keyA = Utils.GetContentKeyValue(a.content, "name")
        const keyB = Utils.GetContentKeyValue(b.content, "name")

        if (!keyA) {
          return 1
        }
        if (!keyB) {
          return -1
        }

        if (keyA < keyB) {
          return -1
        } else if (keyA > keyB) {
          return 1
        }

        return 0
      })
      .map(item => {
        return (
          <option id={item.id} key={item.id}>
            {Utils.GetContentKeyValue(item.content, "name")}
          </option>
        )
      })
  }

  getCurrentProduct = () => {
    const { products, formValues } = this.state

    for (let i in products) {
      const p = products[i]

      if (p.id === formValues.answer_product_id) {
        return p
      }
    }
  }

  selectVariation = (variationID, optionID) => {
    const { selectedVariations } = this.state

    selectedVariations[variationID] = optionID

    this.setState({
      selectedVariations: selectedVariations,
    })
  }

  getForm = () => {
    const { contentMap, language } = this.props

    const product = this.getCurrentProduct()

    return (
      <Form
        product={product}
        language={language}
        noForm={true}
        contentMap={contentMap}
        selectVariation={this.selectVariation}
        // setCartDisabled={this.setCartDisabled}
        setCartDisabled={() => {}}
      />
    )
  }

  getPersonalisation = () => {
    const product = this.getCurrentProduct()

    if (!product) {
      return (
        <div className="row mb-2">
          <div className="col-12 col-md-6 ar-4-3"></div>
          <div className="col-12 col-md-6 mt-2 my-auto"></div>
        </div>
      )
    }

    if (!product.variations || product.variations.length === 0) {
      return (
        <div className="row mb-2">
          <div className="col-12 col-md-6 ar-4-3">{this.getProductImage()}</div>
          <div className="col-12 col-md-6 mt-2 my-auto">
            {Utils.GetContentKeyValue(product.content, "description")}
          </div>
        </div>
      )
    }

    return (
      <div className="row mb-2">
        <div className="col-12 col-md-6 ar-4-3">{this.getProductImage()}</div>
        <div className="col-12 col-md-6 mt-2">{this.getForm()}</div>
      </div>
    )
  }

  getProductImage = () => {
    const product = this.getCurrentProduct()

    if (!product) {
      return <div></div>
    }

    const productName = Utils.GetContentKeyValue(product.content, "name")
    const productThumbnail = Utils.GetContentKeyValue(
      product.content,
      "thumbnails"
    )

    return (
      <img
        src={productThumbnail}
        alt={productName}
        title={productName}
        width={"100%"}
      />
    )
  }

  isWeekday(date) {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  render() {
    const { contentMap, commonContentMap, showTitle, mCss } = this.props
    const { formValues, loadingForm, countries, startDate } = this.state

    const marginCss = mCss ? " " + mCss : ""
    const titleCss = showTitle ? "" : " d-none"

    return (
      <div
        onMouseMove={this.onMouseMove}
        onClick={this.onMouseClick}
        onKeyDown={this.onKeyDown}
      >
        <div className={"container" + marginCss}>
          <div className={"row" + titleCss}>
            <div className="col-10 font-weight-bold text-uppercase"></div>
            <div className="col-2 text-right">
              <a
                href=""
                title={commonContentMap.close.value}
                className="exit-ns-toggler"
                onClick={this.onClick}
              >
                <i className="fa fa-window-close" />
              </a>
            </div>
          </div>
        </div>
        {loadingForm ? (
          <div className={"container" + marginCss}>
            <Loading />
          </div>
        ) : (
          <div className={"container" + marginCss}>
            <form onSubmit={this.onSubmit}>
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_email.value}
                </h6>
                <input
                  className={this.errorCss(
                    "answer_email",
                    "col-6 form-control"
                  )}
                  type="text"
                  id={this.prefixID("answer_email")}
                  name="answer_email"
                  value={formValues.answer_email}
                  onChange={this.handleChange}
                  placeholder={contentMap.question_email.value}
                  aria-label={contentMap.question_email.value}
                />
              </div>
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_first_name.value}
                </h6>
                <input
                  className={this.errorCss(
                    "answer_first_name",
                    "col-6 form-control"
                  )}
                  type="text"
                  id={this.prefixID("answer_first_name")}
                  name="answer_first_name"
                  value={formValues.answer_first_name}
                  onChange={this.handleChange}
                  placeholder={contentMap.question_first_name.value}
                  aria-label={contentMap.question_first_name.value}
                />
              </div>
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_product.value}
                </h6>
                <select
                  className={this.errorCss(
                    "answer_product",
                    "col-6 form-control"
                  )}
                  id={this.prefixID("answer_product")}
                  name="answer_product"
                  value={formValues.answer_product}
                  onChange={this.handleChange}
                  aria-label={contentMap.question_product.value}
                >
                  {this.createProductOptions()}
                </select>
              </div>
              {this.getPersonalisation()}
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_quantity.value}
                </h6>
                <input
                  className={this.errorCss(
                    "answer_quantity",
                    "col-6 form-control"
                  )}
                  type="number"
                  id={this.prefixID("answer_quantity")}
                  name="answer_quantity"
                  value={formValues.answer_quantity}
                  onChange={this.handleChange}
                  placeholder={contentMap.question_quantity.value}
                  aria-label={contentMap.question_quantity.value}
                />
              </div>
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_delivery_date.value}
                </h6>
                <DatePicker
                  className="w-100 form-control"
                  wrapperClassName="col-6"
                  dateFormat="dd/MM/yyyy"
                  locale="nl"
                  minDate={startDate}
                  filterDate={this.isWeekday}
                  selected={formValues.answer_delivery_date_picker}
                  onChange={date =>
                    this.handleChange({
                      target: {
                        name: "answer_delivery_date_picker",
                        value: date,
                      },
                    })
                  }
                />
              </div>
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_country.value}
                </h6>
                <select
                  id={"answer_country"}
                  name={"answer_country"}
                  value={formValues.answer_country}
                  className="col-6 form-control"
                  onChange={this.handleChange}
                  aria-label={contentMap.question_product.value}
                >
                  {Utils.GetCountryOptions(countries)}
                </select>
              </div>
              <div className="row mb-2">
                <h6 className="col-6 detail-option-heading">
                  {contentMap.question_comment.value}
                </h6>
                <textarea
                  className="col-6 form-control"
                  id={this.prefixID("answer_comment")}
                  name="answer_comment"
                  value={formValues.answer_comment}
                  onChange={this.handleChange}
                  placeholder=""
                  aria-label={contentMap.question_comment.value}
                />
              </div>
              <div className="row">
                <input
                  className="d-none form-control"
                  type="text"
                  id={this.prefixID("bulk_validation")}
                  name="bulk_validation"
                  value={formValues.bulk_validation}
                  onChange={this.handleChange}
                  placeholder={commonContentMap.validation.value}
                  aria-label={commonContentMap.validation.value}
                />
                <button
                  className="col-12 btn btn-pink"
                  id={this.prefixID("submit_bulk_form")}
                  type="submit_bulk_form"
                  disabled={this.confirmDisabled()}
                >
                  {commonContentMap.notification_newsletter_submit.value}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    )
  }
}
