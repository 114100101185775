import { createStore } from "redux"

const SET_T = "SET_T"
const SET_MM = "SET_MM"
const SET_MC = "SET_MC"
const SET_TB = "SET_TB"
const SET_CART = "SET_CART"
const SET_WISHLIST = "SET_WISHLIST"

export function setT(t) {
  return { type: SET_T, t }
}

export function setMM(mm) {
  return { type: SET_MM, mm }
}

export function setMC(mc) {
  return { type: SET_MC, mc }
}

export function setTB(tb) {
  return { type: SET_TB, tb }
}

export function setCart(updatedCart) {
  return { type: SET_CART, updatedCart }
}

export function setWishlist(updatedWishlist) {
  return { type: SET_WISHLIST, updatedWishlist }
}

const initialState = {}

function tApp(state, action) {
  if (typeof state === "undefined") {
    return initialState
  }

  switch (action.type) {
    case SET_T:
      return Object.assign({}, state, {
        t: action.t,
      })
    case SET_MM:
      return Object.assign({}, state, {
        mm: action.mm,
      })
    case SET_MC:
      return Object.assign({}, state, {
        mc: action.mc,
      })
    case SET_TB:
      return Object.assign({}, state, {
        tb: action.tb,
      })
    case SET_CART:
      return Object.assign({}, state, {
        updatedCart: action.updatedCart,
      })
    case SET_WISHLIST:
      return Object.assign({}, state, {
        updatedWishlist: action.updatedWishlist,
      })
    default:
      return state
  }
}

export const store = createStore(tApp)
