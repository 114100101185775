import React, { Component } from "react"
import Bulk from "../Bulk/Bulk"
import { Store } from "react-notifications-component"

export default class NewsletterNotification extends Component {
  componentDidMount() {
    document.body.style.overflow = "hidden"
  }

  componentWillUnmount() {
    document.body.style.overflow = "scroll"
  }

  removeSelf = () => {
    Store.removeNotification(this.props.id)
  }

  render() {
    const { contentMap, commonContentMap, language } = this.props

    return (
      <div className="container bulk-notification bulk-form-scroll">
        <Bulk
          contentMap={contentMap}
          commonContentMap={commonContentMap}
          removeNotification={this.removeSelf}
          showTitle={true}
          mCss={"mt-4 mb-4"}
          prefix="notf_"
          language={language}
        />
      </div>
    )
  }
}
